
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Share = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Share" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13 10a2.98994 2.98994 0 00-2.41315 1.23431l-4.7304-2.36517A2.95841 2.95841 0 006 8a2.9577 2.9577 0 00-.14362-.86938l4.73029-2.36512A2.98752 2.98752 0 1010 3a2.95874 2.95874 0 00.14349.8689L5.413 6.23419a3 3 0 10.00013 3.5315l4.7304 2.36517A2.95841 2.95841 0 0010 13a3 3 0 103-3zm0-9a2 2 0 11-2 2 2.00229 2.00229 0 012-2zM3 10a2 2 0 112-2 2.00229 2.00229 0 01-2 2zm10 5a2 2 0 112-2 2.00229 2.00229 0 01-2 2z" />
</svg>
    </HvIconBase>
)};

Share.propTypes = HvIconBase.propTypes;

export default Share;