
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleRunningLowerClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleRunningLowerClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.994 6.0096H24V4.01034h-2.339a6.00073 6.00073 0 00-11.321 0H8V6.0096h2.006a5.97537 5.97537 0 002.909 5.13411C10.6 12.937 9 16.67266 9 21.00406c0 .33588.01.66976.028.99963l-4.557.032H4v5.99778l10-.032.175-.46583 1.766-4.70926 1.108 3.32177L15 32h6l1.966-6.09775-1.3-3.89855h1.3c.024-.32888.034-.66276.034-.99963 0-4.3324-1.6-8.068-3.915-9.86036a5.99035 5.99035 0 002.909-5.13411zM16 2.01108a3.98229 3.98229 0 013.445 1.99926h-6.89A3.98229 3.98229 0 0116 2.01108zm3.26 12.29546a12.339 12.339 0 011.7 5.69789h-9.938a12.72185 12.72185 0 011.716-5.69789c1.27-1.99926 2.588-2.29915 3.261-2.29915s1.991.29989 3.261 2.29915zM16 10.00813a3.99926 3.99926 0 01-4-3.99853h8a3.99926 3.99926 0 01-4 3.99853z" />
</svg>
    </HvIconBase>
)};

MaleRunningLowerClothesHat.propTypes = HvIconBase.propTypes;

export default MaleRunningLowerClothesHat;