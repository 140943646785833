
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Champion = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Champion" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M15 2.52077h-2.5l-.5-1H4s-.4 1-.4 1.1H1a5.382 5.382 0 00-1 2 2.989 2.989 0 002.9 3H3a2.76864 2.76864 0 00.9-.1 5.684 5.684 0 003.6 2v4.5H4v1h8v-1H8.5v-4.5a5.04 5.04 0 003.6-2.1 2.76785 2.76785 0 00.9.1 2.989 2.989 0 003-2.9v-.1a4.21322 4.21322 0 00-1-2zm-12 4a2.006 2.006 0 01-2-2 3.12629 3.12629 0 01.5-1h1.7a3.5547 3.5547 0 00-.2 1 4.058 4.058 0 00.4 1.9.6.6 0 01-.4.1zm5.2 2a4 4 0 01-4.2-3.7v-.3a6.63672 6.63672 0 01.6-2h6.8a6.63672 6.63672 0 01.6 2 4.043 4.043 0 01-3.8 4zm4.8-2a.6.6 0 01-.4-.1 4.058 4.058 0 00.4-1.9 3.55247 3.55247 0 00-.2-1h1.7a1.38907 1.38907 0 01.5 1 2.006 2.006 0 01-2 2z" />
</svg>
    </HvIconBase>
)};

Champion.propTypes = HvIconBase.propTypes;

export default Champion;