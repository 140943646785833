
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Tool = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Tool" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M4.79431 14.03412l4.59619-4.5962.49494.07061a3.97211 3.97211 0 003.39415-1.13127 4.01469 4.01469 0 001.03528-3.86371L12.987 5.84145l-.41363.00069h-2.41551V3.42665l.00069-.41363 1.3279-1.32789a4.01606 4.01606 0 00-3.86371 1.03528 3.97211 3.97211 0 00-1.13127 3.39415l.07061.49494-4.5962 4.59619 2.82843 2.82843m0 1.41421L.55167 11.20569l4.94975-4.94975a5.00069 5.00069 0 017.70739-4.879l-2.05054 2.05058v1.41421h1.41421L14.623 2.79119a5.01258 5.01258 0 01-.63632 6.29318 4.96269 4.96269 0 01-4.24264 1.41421z" />
</svg>
    </HvIconBase>
)};

Tool.propTypes = HvIconBase.propTypes;

export default Tool;