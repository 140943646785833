
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const HaulTruck = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="HaulTruck" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 8l-5-5H9v9h-.5V2H11V1H7.5v3H.28668l2.0849 5.06836A2.99919 2.99919 0 106 12h1.5v1H11a2 2 0 004 0h1zM3 14a1.99483 1.99483 0 01-.23541-3.97632A1.96908 1.96908 0 013 10a2.02606 2.02606 0 012 2 2 2 0 01-2 2zm2.82245-3a2.99938 2.99938 0 00-2.37933-1.95532L1.7793 5H7.5v6zM13 14a1 1 0 111-1 1 1 0 01-1 1zm1.7226-2a1.98368 1.98368 0 00-3.4452 0H10V4h.793L15 8.207V12z" />
</svg>
    </HvIconBase>
)};

HaulTruck.propTypes = HvIconBase.propTypes;

export default HaulTruck;