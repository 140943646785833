
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Snow = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Snow" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M14.5 7.5V6h-1v1.5h-2.551a2.971 2.971 0 00-.509-1.233L14.01 2.7l-.71-.71-3.57 3.57a2.971 2.971 0 00-1.23-.509V2.5H10v-1H8.5V0h-1v1.5H6v1h1.5v2.551a2.971 2.971 0 00-1.233.509L2.7 1.989l-.707.711 3.571 3.567A2.971 2.971 0 005.055 7.5H2.5V6h-1v1.5H0v1h1.5V10h1V8.5h2.551a2.971 2.971 0 00.509 1.233L1.989 13.3l.707.707 3.571-3.571a2.971 2.971 0 001.233.509V13.5H6v1h1.5V16h1v-1.5H10v-1H8.5v-2.551a2.971 2.971 0 001.233-.509l3.57 3.57.707-.707-3.57-3.57a2.971 2.971 0 00.509-1.233H13.5V10h1V8.5H16v-1zm-4.571 1a1.914 1.914 0 01-.213.509 1.979 1.979 0 01-.707.707 1.928 1.928 0 01-.509.213 1.986 1.986 0 01-1.51-.212 2.006 2.006 0 01-.707-.708A1.892 1.892 0 016.071 7.5a1.965 1.965 0 01.212-.51 2.012 2.012 0 01.707-.707 1.992 1.992 0 012.726.706 1.934 1.934 0 01.213.51 1.808 1.808 0 010 1.001z" />
</svg>
    </HvIconBase>
)};

Snow.propTypes = HvIconBase.propTypes;

export default Snow;