
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Sun = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Sun" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.5 0h1v3h-1zm0 13h1v3h-1zM13 7.5h3v1h-3zm-13 0h3v1H0zm1.985026135-4.803521445l.7070203865-.7071931653 2.1215794959 2.1210611596-.7070203865.7071931653zm9.201814142 9.1935557074l.7070697562-.7071438042 2.1214314127 2.1212092686-.7070697562.7071438042zm-.0045477444-7.7836554222L13.3035388268 1.98498445l.7071314634.7070820981-2.1212462942 2.1213943903zM1.989746694 13.3081436061l2.1212462942-2.1213943903.7071314634.7070820981-2.1212462942 2.1213943903zM8 4a4 4 0 104 4 4 4 0 00-4-4zm0 7a3 3 0 113-3 3 3 0 01-3 3z" />
</svg>
    </HvIconBase>
)};

Sun.propTypes = HvIconBase.propTypes;

export default Sun;