
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Hotspot = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Hotspot" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8.00009 9.9995a2 2 0 112-2 2 2 0 01-2 2zm0-3a1 1 0 101 1 1 1 0 00-1-1z" />
  <g fill={colorArray[0]} className="color0" >
    <path d="M12.00009 7.9995a4 4 0 01-2.36 3.64l.733.733a4.953 4.953 0 000-8.746l-.734.734a4 4 0 012.361 3.639zm-8 0a4 4 0 012.361-3.639l-.734-.734a4.953 4.953 0 000 8.746l.733-.733a4 4 0 01-2.36-3.64z" />
    <path d="M16.00009 7.9995a7.991 7.991 0 00-3.438-6.562l-.718.718a6.989 6.989 0 010 11.688l.719.719a7.994 7.994 0 003.437-6.563zm-15 0a7 7 0 013.156-5.844l-.718-.718a7.983 7.983 0 000 13.125l.719-.719a7 7 0 01-3.157-5.844z" />
  </g>
</svg>
    </HvIconBase>
)};

Hotspot.propTypes = HvIconBase.propTypes;

export default Hotspot;