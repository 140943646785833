
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Instructor = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1,"none"];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Instructor" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 12.042V12l-.012-11H0v12h2.882a5.474 5.474 0 00-.382 2h11a5.474 5.474 0 00-.382-2H16zM3.622 14a4.488 4.488 0 018.756 0zM6 7a2 2 0 112 2 2 2 0 01-2-2m6.61 5a5.52256 5.52256 0 00-3.2698-2.329 3.00811 3.00811 0 00.792-4.81142 2.91724 2.91724 0 00-2.54689-.8534A3.00434 3.00434 0 006.66385 9.671 5.51655 5.51655 0 003.40206 12H1V2h14l.012 10z" />
  <path fill={colorArray[1]} className="color1"  stroke="#414141" strokeMiterlimit={10} strokeWidth={3} d="M-6-6h29v29H-6z" />
</svg>
    </HvIconBase>
)};

Instructor.propTypes = HvIconBase.propTypes;

export default Instructor;