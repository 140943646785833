
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Settings = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Settings" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 8.5v-1h-2a6.06112 6.06112 0 00-1.4-3.4L14 2.7l-.7-.7-1.4 1.4A5.7838 5.7838 0 008.5 2V0h-1v2a6.05973 6.05973 0 00-3.4 1.4L2.6 1.9l-.7.7 1.5 1.5A5.784 5.784 0 002 7.5H0v1h2a6.06112 6.06112 0 001.4 3.4L2 13.3l.7.7 1.4-1.4A5.7838 5.7838 0 007.5 14v2h1v-2a6.05973 6.05973 0 003.4-1.4l1.4 1.4.7-.7-1.4-1.4A5.784 5.784 0 0014 8.5zM8 3a5.127 5.127 0 013.4 1.3l.3.3A5.12678 5.12678 0 0113 8a4.689 4.689 0 01-1.3 3.3l-.4.4a4.958 4.958 0 01-6.8-.1l-.2-.2a5.27 5.27 0 01-1.4-3.5A5.083 5.083 0 018 3z" />
</svg>
    </HvIconBase>
)};

Settings.propTypes = HvIconBase.propTypes;

export default Settings;