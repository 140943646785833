
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const LightRain = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="LightRain" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.28977562 10.8903219457l.2424299081-.9701689232.9701689233.242429908-.242429908.9701689233zm-.487525928 1.9456819149l.2422097775-.970223904.970223904.2422097776-.2422097776.9702239038zm-.4533365912 1.8208841744l.2107077742-.844098474.970228131.2421928439-.210707774.844098474zm1.4242003613-5.7023908663l.2424468407-.9701646919.970164692.2424468407-.2424468407.9701646919z" />
  <path fill={colorArray[0]} className="color0"  d="M15.461 7.623a3 3 0 00-1.97-2.349c0-.055.006-.111.006-.167A2.5 2.5 0 009.833 2.9a4 4 0 00-7.327 2.206v.169a3 3 0 001 5.831h.838l.056-.184.861.215.243-.969-.969-.243-.045.181h-.984a2 2 0 01-.664-3.888l.7-.246-.036-.741v-.125A3 3 0 019 3.448l.5.754.8-.423a1.479 1.479 0 01.7-.172 1.5 1.5 0 011.5 1.5v.1l-.047.755.713.251a2 2 0 01-.336 3.862l-.165.032h-1.236l-.01.042-.89-.223-.242.97.97.242.008-.031h1.225l.494-.039a3 3 0 002.477-3.445zm-5.6605030232 5.208921648l.2443258996-.9696931756.9696931755.2443258996-.2443258995.9696931756z" />
  <path fill={colorArray[0]} className="color0"  d="M10.7710039892 8.9524540353l.2423960426-.970177385.970177385.2423960426-.2423960426.970177385zm-6.9673836296 3.885221537l.2422097775-.970223904.9702239039.2422097775-.2422097775.970223904zm.9711525805-3.8806843278l.2424468407-.970164692.970164692.2424468407-.2424468408.970164692z" />
</svg>
    </HvIconBase>
)};

LightRain.propTypes = HvIconBase.propTypes;

export default LightRain;