
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const WaterTruck = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="WaterTruck" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M6.254 7.50163c0-1.106-2-3-2-3s-2 1.894-2 3a2 2 0 004 0zm-2 1a1 1 0 01-1-1s.026-.374.793-1.3c.07-.085.14-.167.207-.244.072.083.146.169.219.259.755.922.781 1.291.781 1.291a1 1 0 01-1 .994zM16 6.79563l-4.794-4.793H9v9.493h-.5v-9.493H0v9.993h.006a2 2 0 102-2 1.974 1.974 0 00-1.007.282v-7.275h6.5v7.676a2 2 0 10.436 1.818h2.142a1.99 1.99 0 003.856.006H16zm-13.994 4.2a1 1 0 11-1 1 1 1 0 011-1zm4 2a1 1 0 111-1 1 1 0 01-1 1zm6 0a1 1 0 111-1 1 1 0 01-1 1zm2.994-1.493h-1.063a1.99 1.99 0 00-3.86-.006H10v-8.494h.793L15 7.20963z" />
</svg>
    </HvIconBase>
)};

WaterTruck.propTypes = HvIconBase.propTypes;

export default WaterTruck;