
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleWalking = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleWalking" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M25 32l-3.333-9.99655h1.3c.019-.32888.029-.66277.029-.99965 0-4.33251-1.6-8.06822-3.915-9.8606a6.00091 6.00091 0 10-6.17 0C10.6 12.93658 9 16.67229 9 21.0038c0 .33588.01.66977.028.99965h1.305L7 32h6l3-8.99689L19 32zm-2.775-1.99931h-1.783l-2.667-7.99724h1.784zM12 6.009a4 4 0 114 3.99861A3.99931 3.99931 0 0112 6.009zm2.891 13.99516l-.456 1.36753-2.877 8.629H9.774l2.456-7.36446.878-2.63209h-2.075a12.33255 12.33255 0 011.7-5.698c1.27-1.99931 2.588-2.29921 3.261-2.29921s1.991.2999 3.261 2.29921a12.34055 12.34055 0 011.7 5.698z" />
</svg>
    </HvIconBase>
)};

MaleWalking.propTypes = HvIconBase.propTypes;

export default MaleWalking;