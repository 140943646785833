
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Like = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Like" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13.69343 5.0065h-3.663l-.049-2.372c0-2.408-2.588-2.641-3.591-2.641-.241 0-.391.013-.391.013v3.755a3.128 3.128 0 01-2 2.912v-.667h-4v10h4v-.373a5.48109 5.48109 0 001.976.373h5.8c3 0 3.254-2.227 3.254-2.227l.97-6.215a2.49 2.49 0 00-2.306-2.558zm-10.694 10h-2v-8h2zm11.039-1.381v.031a1.549 1.549 0 01-.345.676 2.43 2.43 0 01-1.921.674h-5.8a4.97388 4.97388 0 01-1.3-.184 1.56 1.56 0 01-.573-.285.268.268 0 01-.1-.231v-6.55a5.5399 5.5399 0 001.032-.5 3.963 3.963 0 001.968-3.495v-2.736a3.70009 3.70009 0 011.141.292 1.3 1.3 0 01.841 1.317v.02l.05 2.373.02.979h4.587a1.96111 1.96111 0 01.744.27 1.357 1.357 0 01.617 1.217z" />
</svg>
    </HvIconBase>
)};

Like.propTypes = HvIconBase.propTypes;

export default Like;