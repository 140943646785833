
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Container = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Container" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M-.00003052 16V7H5.46994l1.37109-1.372c-.25976-.18471-.47163-.42883-.61792-.71202-.14629-.28319-.22283-.59724-.22314-.91598.00011-.44331.14755-.87402.41907-1.22445.27151-.35043.6517-.60072 1.08093-.71155V1h1v2h-.5c-.26522 0-.5195.10536-.70703.29289-.18754.18754-.29297.44189-.29297.70711.00035.18827.05381.37262.1543.53184.10048.15921.24389.28683.41369.36816l.41504-.415.01697.016.01599-.016.41504.415c.16998-.08119.31352-.20875.41418-.36797.10067-.15923.15435-.34365.15479-.53203h1c-.00015.31891-.07648.63316-.22278.91654-.1463.28337-.35826.52765-.61816.71246L10.53 7H16v9H-.00003052zM15 15V8H.999969v7H15zM9.11594 7l-1-1h-.224l-1 1h2.224zM12 14V9h1v5h-1zm-3.00003 0V9h1v5h-1zm-3 0V9h1v5h-1zm-3 0V9h1v5h-1zm3-13V0h4v1h-4z" />
</svg>
    </HvIconBase>
)};

Container.propTypes = HvIconBase.propTypes;

export default Container;