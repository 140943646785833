
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Radar = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Radar" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M15.5 6.23859a1.749 1.749 0 00-1.747-1.751h-.002a1.79951 1.79951 0 00-.362.038A6.991 6.991 0 008.5 2.0156V.9876h-1v1.023a6.97 6.97 0 00-4.02 1.641 1.74494 1.74494 0 00-1.994 2.791 7 7 0 1013.372 1.139 1.737 1.737 0 00.642-1.34301zm-7 2.749v-2.949a3 3 0 01-.5 5.949 3.04007 3.04007 0 01-.637-.071 1.75 1.75 0 00-2.292-2.292A3.03955 3.03955 0 015 8.98758a3 3 0 012.5-2.949v2.949zm-2 2.25a.72409.72409 0 01-.064.3.749.749 0 11-.984-.985.74.74 0 01.3-.063.751.751 0 01.748.74798zm-4.5-6a.741.741 0 01.622-.725.68592.68592 0 01.129-.026.744.744 0 01.069 1.486c-.024 0-.045.014-.069.014a.741.741 0 01-.726-.628.70079.70079 0 01-.025-.12zm6 9.75a5.982 5.982 0 01-5.633-8.045 1.74157 1.74157 0 001.868-2.62 5.961 5.961 0 013.265-1.291v2.007A3.992 3.992 0 004 8.98758a3.94383 3.94383 0 00.253 1.357 1.74 1.74 0 002.39 2.39 3.94412 3.94412 0 001.357.253 3.99 3.99 0 00.5-7.949v-2.023a5.982 5.982 0 013.993 2.009 1.747 1.747 0 001.258 2.963c.053 0 .1-.011.156-.016A5.966 5.966 0 018 14.98756zm6.478-8.642a.741.741 0 01-.727.642.65712.65712 0 01-.109-.022.742.742 0 01-.642-.727.7241.7241 0 01.114-.373.74.74 0 01.637-.378.71888.71888 0 01.37.113.741.741 0 01.379.638.65581.65581 0 01-.022.10699z" />
</svg>
    </HvIconBase>
)};

Radar.propTypes = HvIconBase.propTypes;

export default Radar;