
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const PartlyCloudy = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="PartlyCloudy" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13 10.16c0-.055.006-.111.006-.167a2.685 2.685 0 00-.041-.4A4.955 4.955 0 0016 5.464 4 4 0 0110.548 0a5.007 5.007 0 00-4.417 5.994c-.043 0-.083-.008-.126-.008a4 4 0 00-4 4.006v.169a3 3 0 001 5.839h9.482v-.039A3.006 3.006 0 0013 10.16zM8.075 2.281A4 4 0 019.05 1.5c-.015.158-.023.318-.023.479a5.007 5.007 0 005 5.007c.161 0 .321-.008.479-.023a3.922 3.922 0 01-1.9 1.688 2.491 2.491 0 00-3.271-.875 4 4 0 00-2.1-1.591 3.952 3.952 0 01-.2-1.2 4 4 0 011.04-2.704zM13.9 13.655a2 2 0 01-1.565 1.316l-.168.029h-9.16a2 2 0 01-.664-3.9l.7-.246-.032-.742v-.121a3 3 0 013-3 2.865 2.865 0 01.479.06A2.972 2.972 0 018.5 8.332l.5.754.66-.35.14-.073a1.493 1.493 0 012.189 1.229c0 .033.01.064.01.1v.1l-.047.756.714.251a2 2 0 011.234 2.556z" />
</svg>
    </HvIconBase>
)};

PartlyCloudy.propTypes = HvIconBase.propTypes;

export default PartlyCloudy;