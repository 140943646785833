
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Cloud = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Cloud" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M11 13h1.38637a2.58224 2.58224 0 002.4602-1.62089A2.50726 2.50726 0 0013.558 8.234l-.648-.3.077-.711a2 2 0 00-2.458-2.164l-.693.166-.31327-.48949A3.60171 3.60171 0 005.81788 3.0661 3.50969 3.50969 0 002.999 6.5v.612l-.39427.22789a3.09074 3.09074 0 00-1.566 3.17777A3.00739 3.00739 0 003.999 13H11m1.39934 1H4.1634a4.1193 4.1193 0 01-3.92714-2.6247A4.00989 4.00989 0 011.999 6.535V6.5a4.41528 4.41528 0 012.07868-3.82744A4.53923 4.53923 0 0110.299 4.083 3 3 0 0113.999 7a2.96564 2.96564 0 01-.018.328 3.508 3.508 0 011.15822 5.48284A3.56173 3.56173 0 0112.39934 14z" />
</svg>
    </HvIconBase>
)};

Cloud.propTypes = HvIconBase.propTypes;

export default Cloud;