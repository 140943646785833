
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Cluster = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Cluster" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M15.38 12.55957a1.994 1.994 0 00-1.38049-.54957v-.00195a1.982 1.982 0 00-.588.09985l-2.97413-4.95678a3.99422 3.99422 0 10-4.76789.08814L4.05249 10.2A2.99453 2.99453 0 106 13a2.98024 2.98024 0 00-1.08557-2.292l1.633-2.99A3.9317 3.9317 0 009.584 7.671l2.97553 4.959a1.995 1.995 0 102.82047-.07043zM3 15a2 2 0 112-2 2.00006 2.00006 0 01-2 2zM5 4a3 3 0 113 3 3 3 0 01-3-3zm8.99951 11.00806a1 1 0 111-1 1.00006 1.00006 0 01-1 1z" />
</svg>
    </HvIconBase>
)};

Cluster.propTypes = HvIconBase.propTypes;

export default Cluster;