
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const ShowerDay = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="ShowerDay" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M9.5 0h1v2.01h-1zM14 5.53h2v1h-2zM7.528 2.832L6.114 1.41l-.707.711 1.414 1.422.707-.711zm7.069-.71l-.707-.711-1.414 1.422.707.711 1.414-1.422zm-7.322 9.79l.97.244.242-.976-.969-.243-.243.975zm-.484 1.951l.969.243.243-.974-.97-.244-.242.975zm-.485 1.942l.983.195.229-.918-.97-.243-.242.966zm2.968-3.893l.971.244.242-.976-.97-.243-.243.975zm-.605 2.438l.97.245.242-.976-.97-.243-.242.974zm-4.22 0l.969.245.243-.976-.969-.243-.243.974zm.607-2.438l.968.244.243-.976-.97-.243-.241.975zm5.461 3.893l.98.195.23-.918-.97-.243-.24.966zm2.179-4.625l-.97-.243-.242.975.97.244.242-.976zM2.088 15.801l.483.124.5.07.226-.915-.97-.243-.239.964zm.967-3.889l.97.244.242-.976-.97-.243-.242.975z" />
  <path fill={colorArray[0]} className="color0"  d="M13 9.1v-.05a2.5 2.5 0 00-.455-1.439A2.993 2.993 0 107.135 5.2a3.974 3.974 0 00-4.958 2.675c-.031.1-.057.2-.079.308A3.018 3.018 0 003 14.075l.019-.1.517.13.242-.974-.969-.245-.04.159A2.008 2.008 0 012.4 9.14l.551-.176.123-.564A3.021 3.021 0 016 6.032a3.175 3.175 0 012.5 1.349l.5.756.383-.2.416-.22a1.473 1.473 0 011.334-.028 1.5 1.5 0 01.71.7 1.476 1.476 0 01.157.659v.021l-.025.847.825.168a1.508 1.508 0 01-.3 2.985h-.536l-.723-.181-.241.975.582.146.4.066h.518A2.515 2.515 0 0013 9.1zm-1.207-2.194a2.46 2.46 0 00-2.457-.082A4.04 4.04 0 008.053 5.6a2 2 0 113.74 1.3z" />
</svg>
    </HvIconBase>
)};

ShowerDay.propTypes = HvIconBase.propTypes;

export default ShowerDay;