
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Job = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Job" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M1.3515 7.3555l3.5-3.5 2.148 2.144v-5h-5l2.146 2.147-3.5 3.5zm4.648-5.356v1.586l-1.585-1.586zm9.355 7.364l-.707-.707-3.5 3.5-2.16-2.157v5h5l-2.133-2.134zm-5.367 4.636v-1.586l1.585 1.586zm.012-6.987h5v-5l-2.134 2.134-3.5-3.5-.707.707 3.5 3.5zm4-2.586v1.586h-1.585zm-8 4.561h-5v5l2.134-2.134 3.5 3.5.707-.707-3.5-3.5zm-4 2.585v-1.585h1.586z" />
</svg>
    </HvIconBase>
)};

Job.propTypes = HvIconBase.propTypes;

export default Job;