
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleRunning = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleRunning" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M23 21.00808c0-4.33082-1.6-8.06508-3.915-9.85676a6.002 6.002 0 10-6.17 0C10.6 12.944 9 16.67826 9 21.00808c0 .33575.01.6695.028.99926L4 22.04232v5.96062l10.08827-.03529 1.95813-5.21987 1.11778 3.35088L15.0971 32H21l1.966-6.09552-1.3-3.89714h1.3c.024-.32876.034-.66251.034-.99926zM12 6.02462a4 4 0 114 3.99706 3.99853 3.99853 0 01-4-3.99706zm2.84851 13.98419l-.48644 1.29753-1.76194 4.69807L6 26.02459v-2.01871l5-.02726-.01-3.19428a12.68478 12.68478 0 011.736-6.4647c1.26852-1.99621 2.585-2.29564 3.2572-2.29564.67222 0 1.98868.29943 3.2572 2.29564a12.31782 12.31782 0 011.698 5.68917zm6.01449 5.90367l-1.32 4.089h-1.724l1.117-3.19066.226-.64452-.216-.64753-1.171-3.51142h1.784z" />
</svg>
    </HvIconBase>
)};

MaleRunning.propTypes = HvIconBase.propTypes;

export default MaleRunning;