
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Excavator = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Excavator" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8 11h1v1H8zm-5.5-.5h1v1h-1zm10.62579-8.4L8.957 6.278 4.69051 2H1.9927v6.184A2.99421 2.99421 0 002.98931 14h5.48133a2.50139 2.50139 0 00.99661-4.788v-2.03L12.777 3.865l2.10982 5.086L10.96216 14h3.98642v-3.21L16 9.033zM2.98931 3h1.28861l4.19272 4.207V9L2.98931 8zm6.97624 8.5A1.49746 1.49746 0 018.47064 13H2.98931a2.00041 2.00041 0 01-.07973-4l5.38167.982L8.381 10h.08969a1.49746 1.49746 0 011.49486 1.5zm3.98645.5v1h-.91588l.91588-1.149z" />
</svg>
    </HvIconBase>
)};

Excavator.propTypes = HvIconBase.propTypes;

export default Excavator;