
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Memory = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Memory" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M11.294 14.889H0V9.08901c.308759-.00967.601064-.14146.812739-.36645.211671-.22499.325421-.52478.316261-.83355.00262-.31431-.11509-.6177-.329-.848-.102218-.11023-.225944-.19833-.363539-.25887C.298866 6.7216.150322 6.6899 0 6.68901v-.8a1.7856268 1.7856268 0 01.227.015c-.075205-.01047-.1510705-.01549-.227-.015v-3h16v3h-.006c-.0669.00002-.1337.00436-.2.013.0684-.0083.1371-.01264.206-.013v.8c-.3183 0-.6235.12643-.8485.35147-.2251.22504-.3515.53027-.3515.84853s.1264.62348.3515.84853c.225.22504.5302.35147.8485.35147V14.889h-4.706zm3.706-1V9.58301c-.2721-.19072-.4943-.44421-.6477-.739-.1533-.29479-.2334-.6222-.2334-.9545 0-.33231.0801-.65972.2334-.95451.1534-.29479.3756-.54827.6477-.73899v-2.307H1v2.308c.27429.18827.49817.44102.65195.73603.15379.29502.23278.62329.23005.95597.00306.33302-.07578.66168-.22958.95708-.1538.29539-.37784.54845-.65242.73692V13.889h.882v-1h.941v1h.941v-1h.941v1h.941v-1h.941v1h.941v-1h.941v1h.941v-1h.941v1h.941v-1h.941v1H15zm-1.824-3h-2.823V4.88901h2.824V10.889h-.001zm-1.882-.99999h.941v-4h-.941v4zm-1.882.99999H6.588V4.88901h2.824V10.889zm-1.883-.99999h.941v-4h-.941v4zm-1.882.99999H2.824V4.88901h2.823V10.889zm-1.882-.99999h.941v-4h-.941v4zm-3.765-8V.889008h16V1.88901H0z" />
</svg>
    </HvIconBase>
)};

Memory.propTypes = HvIconBase.propTypes;

export default Memory;