
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleWalkingUpperClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleWalkingUpperClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.667 22.00374h1.3c.022-.32888.033-.66275.033-.99962 0-.50681-.029-.99963-.072-1.49145-.006-.076-.014-.14994-.022-.22591-.047-.47482-.11-.94165-.194-1.39348v-.007a13.74063 13.74063 0 00-.336-1.40947c-.006-.022-.013-.043-.02-.065a13.55319 13.55319 0 00-.445-1.28552v-.007a9.44948 9.44948 0 00-2.823-3.97651A5.98531 5.98531 0 0022 6.00973h2V4.01048h-2.336a6.0007 6.0007 0 00-11.321 0H8v1.99925h2.008a5.97833 5.97833 0 002.909 5.13308c-.009.007-.017.016-.025.023a7.8413 7.8413 0 00-1.545 1.64138l-.045.064a11.25252 11.25252 0 00-1.193 2.21017c-.018.043-.033.087-.05.13-.144.36187-.273.73673-.39 1.12558-.019.063-.039.12395-.057.18693-.122.42884-.229.86968-.316 1.32451-.011.057-.018.117-.028.17393-.07.39086-.125.78971-.168 1.19955-.012.119-.024.23691-.034.35687-.039.46982-.066.94564-.066 1.43146 0 .15894.009.31289.013.46983 0 .17693 0 .35486.015.5298h1.305L7 32h6l3-8.99663L19 32h6zM16 2.01123a3.98226 3.98226 0 013.445 1.99925h-6.89A3.98226 3.98226 0 0116 2.01123zm-4.442 27.98952H9.774l2.456-7.36425.211-.63276h1.783zM12 6.00973h8a4 4 0 01-8 0zm5.775 15.994h1.784l2.666 7.997h-1.783z" />
</svg>
    </HvIconBase>
)};

MaleWalkingUpperClothesHat.propTypes = HvIconBase.propTypes;

export default MaleWalkingUpperClothesHat;