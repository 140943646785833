
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Debug = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Debug" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M12.836 11.5a6.68379 6.68379 0 00-.013-3h.868l.862 1.724.894-.448L14.309 7.5h-1.783a6.2438 6.2438 0 00-.742-1.422L14.5 4.26807V1h-1v2.73193L11.115 5.323a4.9512 4.9512 0 00-.985-.75293A3.43331 3.43331 0 0010.5 3 2.78392 2.78392 0 008 0a2.784 2.784 0 00-2.5 3 3.446 3.446 0 00.369 1.57007 4.97775 4.97775 0 00-.984.75195L2.5 3.73193V1h-1v3.26807L4.216 6.078A6.24415 6.24415 0 003.454 7.5H1.691L.553 9.776l.894.448.862-1.724h.859a6.859 6.859 0 000 3H1.691L.553 13.776l.894.448.862-1.724h1.15A5.03807 5.03807 0 008 16a5.0402 5.0402 0 004.541-3.5h1.15l.862 1.724.894-.448-1.138-2.276zM8 15c-2.206 0-4-2.243-4-5a5.209 5.209 0 012.362-4.56006l.835-.47194-.438-.854A2.44278 2.44278 0 016.5 3c0-1.084.687-2 1.5-2s1.5.916 1.5 2a2.45923 2.45923 0 01-.259 1.114l-.438.854.835.4729A5.20012 5.20012 0 0112 10c0 2.757-1.794 5-4 5z" />
</svg>
    </HvIconBase>
)};

Debug.propTypes = HvIconBase.propTypes;

export default Debug;