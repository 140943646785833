
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const PreviewOff = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="PreviewOff" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8 5a3 3 0 103 3 3 3 0 00-3-3zm0 5a2 2 0 112-2 2 2 0 01-2 2zm0-8C4 2 0 8 0 8s3.582 6 8 6 8-6 8-6-4-6-8-6zm4.91 8.333C11.73 11.55 9.921 13 8 13s-3.751-1.465-4.937-2.694a18.07015 18.07015 0 01-1.852-2.279A21.6202 21.6202 0 013.23 5.684C4.992 3.928 6.642 3 8 3s3.022.936 4.793 2.707a21.85215 21.85215 0 012 2.319 17.98455 17.98455 0 01-1.883 2.307z" />
  <path fill={colorArray[0]} className="color0"  d="M.643735348 14.9982411166L14.6444496157.997526849l.7071067811.7071067812L1.3508421292 15.7053478978z" />
</svg>
    </HvIconBase>
)};

PreviewOff.propTypes = HvIconBase.propTypes;

export default PreviewOff;