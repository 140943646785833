
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleWalkingHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleWalkingHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22 6.00281h2V4.003h-2.342a6.00021 6.00021 0 00-11.315 0H8v1.99981h2a5.99923 5.99923 0 002.909 5.13245c-2.135 1.65482-3.657 4.96646-3.881 8.866L9 28.00043h1.333L9 32h6l1-2.99968L17 32h6l-1.333-3.99957H23l-.029-7.99913c-.223-3.89958-1.746-7.21122-3.88-8.866A5.99923 5.99923 0 0022 6.00281zm-6-3.99957A4.00008 4.00008 0 0119.464 4.003h-6.927A4.0001 4.0001 0 0116 2.00324zm2.443 27.997l-.667-1.99979h1.784l.666 1.99979zm2.532-9.88293l.025.43895v5.44441h-6.109l-.455 1.36585-.878 2.63372h-1.784l.456-1.36686.878-2.63271H11l.027-5.88437a12.01983 12.01983 0 011.884-6.07834A4.17305 4.17305 0 0116 12.00216a4.167 4.167 0 013.09 2.03678 12.02075 12.02075 0 011.885 6.07835zM16 10.00238a3.99979 3.99979 0 01-4-3.99957h8a3.99979 3.99979 0 01-4 3.99957z" />
</svg>
    </HvIconBase>
)};

FemaleWalkingHat.propTypes = HvIconBase.propTypes;

export default FemaleWalkingHat;