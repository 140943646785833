
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MarkerE = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.atmo2,theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MarkerE" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8 14.992l-1.852-3.24L6 11.5H2.663l1.532-5.363L4.234 6l-.039-.137L2.663.5h10.674l-1.532 5.362-.039.138.039.137 1.532 5.363H10l-.144.252z" />
  <path fill={colorArray[1]} className="color1"  d="M8 13.985L9.417 11.5 9.7 11h2.97l-1.351-4.725L11.246 6l.078-.275L12.675 1H3.326l1.349 4.725.079.275-.079.274L3.326 11H6.3l.288.5L8 13.985M8 16l-2.286-4H2l1.714-6L2 0h12l-1.714 6L14 12h-3.714z" />
</svg>
    </HvIconBase>
)};

MarkerE.propTypes = HvIconBase.propTypes;

export default MarkerE;