
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Bicycle = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Bicycle" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  stroke="#414141" strokeMiterlimit={10} strokeWidth={3} d="M-7.5-6.5h29v29h-29z" />
  <path fill={colorArray[1]} className="color1"  d="M13 8a2.97986 2.97986 0 00-.7511.10645L12 7.67529 11.61011 7l-.21405-.37085-.5517-.95557L9.30072 3l-.57733-1H6v1h2.146l2.06549 3.57764L10.45538 7H6.61017L6 5.94312 5.45544 5H3v1h1.87811L6 7.94312 6.03278 8l1.15472 2H5.81628A2.97118 2.97118 0 106 11h2.33557l.22772-.3252.09235-.13183L9 10.051 10.43622 8l.327-.467.2696.467.29443.51A2.99961 2.99961 0 1013 8zM3 13a2 2 0 111.7226-3H3v1h2a2.00006 2.00006 0 01-2 2zm5.10394-3.4126L7.1875 8h2.02789zM13 13a1.9958 1.9958 0 01-1.16864-3.6167l.99225 1.71851.866-.5-.91187-1.57935A1.96286 1.96286 0 0113 9a2 2 0 010 4z" />
</svg>
    </HvIconBase>
)};

Bicycle.propTypes = HvIconBase.propTypes;

export default Bicycle;