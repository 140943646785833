
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MarkerC = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.atmo2,theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MarkerC" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.98608 14.992l-1.885-3.3-.174-.3-.345.06a3.57845 3.57845 0 01-.6.05 3.5 3.5 0 01-.875-6.89l.385-.1-.009-.4v-.114a3.5 3.5 0 117 0v.114l-.01.4.385.1a3.5 3.5 0 01-.872 6.888 3.57845 3.57845 0 01-.6-.05l-.345-.06-.174.3z" />
  <path fill={colorArray[1]} className="color1"  d="M7.98608 13.985l1.451-2.539.347-.608.691.119a3 3 0 001.26-5.863l-.77-.2.021-.794V4a3 3 0 10-6 0v.1l.02.8-.77.2a3 3 0 001.261 5.863l.691-.119.347.608 1.451 2.533m0 2.015l-2.319-4.058a4 4 0 01-1.681-7.816V4a4 4 0 018 0v.126a4 4 0 01-1.679 7.816z" />
</svg>
    </HvIconBase>
)};

MarkerC.propTypes = HvIconBase.propTypes;

export default MarkerC;