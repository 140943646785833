
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Grader = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Grader" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M14.48987 10.021V6.793L9.704 2H7.50028v4.713L1.94954 9.956A2 2 0 103.91861 12.5h1.39093l-.16575.5H4.00548v1h1.85724l.83276-2.5H3.94956a2.00929 2.00929 0 00-.72691-1.131l4.27763-2.5v2.822a1.986 1.986 0 103.4239 1.809h1.15528a1.99725 1.99725 0 102.41041-2.479zm-12.48141 2.93a1 1 0 11.99851-1 .99926.99926 0 01-.99851 1zM8.998 13a1 1 0 11.99851-1 .99926.99926 0 01-.99851 1zm3.05046-1.5h-1.12428a1.95693 1.95693 0 00-2.36154-1.44557q-.03207.00774-.06385.01657V3h.79182l4.20075 4.207v2.814a1.99862 1.99862 0 00-1.44285 1.479zm1.94211 1.451a1 1 0 11.99851-1 .99926.99926 0 01-.99846 1z" />
</svg>
    </HvIconBase>
)};

Grader.propTypes = HvIconBase.propTypes;

export default Grader;