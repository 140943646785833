
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleStandingUpperClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleStandingUpperClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M24.91 19.5235c-.014-.12792-.03-.25384-.047-.38077a12.90817 12.90817 0 00-.261-1.38017l-.014-.056a12.41587 12.41587 0 00-.418-1.29722c-.043-.11393-.087-.22586-.133-.33779a11.79086 11.79086 0 00-.563-1.19928l-.026-.047a11.11441 11.11441 0 00-.692-1.07527c-.066-.09194-.134-.18189-.2-.27183a10.15531 10.15531 0 00-.818-.95143l-.029-.029a9.07855 9.07855 0 00-.919-.79952c-.083-.065-.167-.12693-.253-.18789a8.392 8.392 0 00-1.031-.64461A5.97832 5.97832 0 0022 6.01562h2V4.01683h-2.334a5.99612 5.99612 0 00-11.319 0H8v1.99879h2.011a5.97832 5.97832 0 002.5 4.85409 8.56428 8.56428 0 00-1.042.6506c-.08.058-.158.11593-.237.1759a9.732 9.732 0 00-1.782 1.79892c-.063.082-.124.1649-.185.24885a11.42152 11.42152 0 00-1.3 2.34359c-.042.09994-.082.19988-.121.30881a12.686 12.686 0 00-.7 2.75934c-.017.11693-.031.23286-.044.34979a13.45771 13.45771 0 00-.1 1.5011c0 .26484.013.52369.028.78253a1.95526 1.95526 0 00.008.21687H9v5.99639h3V32h8v-3.9976h3V22.006h1.963c.024-.3288.037-.6616.037-.9994v-.025a13.14967 13.14967 0 00-.09-1.4581zM16 2.018a3.9824 3.9824 0 013.445 1.9988h-6.89A3.9824 3.9824 0 0116 2.018zm-4 3.99762h8a4 4 0 01-8 0zm9 19.988h-3v3.99758h-4v-3.99759h-3V22.006h10z" />
</svg>
    </HvIconBase>
)};

FemaleStandingUpperClothesHat.propTypes = HvIconBase.propTypes;

export default FemaleStandingUpperClothesHat;