
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Priority3 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Priority3" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M5.09082 4.41162H3.03516v7.13867h1.16699v-2.6709h.74219c.90137 0 1.59668-.19873 2.08496-.5957s.73242-.96826.73242-1.71387c0-.70947-.22168-1.24658-.66699-1.61133-.44336-.36474-1.11231-.54687-2.00391-.54687zm1.0498 3.17383c-.28027.2085-.71973.3125-1.31836.3125h-.62011V5.38818h.81055c.53027 0 .9209.10107 1.17188.30273s.37598.50781.37598.91797c-.00001.44288-.13966.76807-.41994.97657zm6.82422 1.91406c0 .68701-.24219 1.2168-.72754 1.58936s-1.1748.55908-2.07031.55908c-.79102 0-1.45801-.12842-2.00195-.38574V10.2417c.30273.1499.62305.26514.96191.34668s.66406.12207.97656.12207c.55371 0 .9668-.10254 1.24023-.30762s.41016-.52246.41016-.95215c0-.38086-.15137-.66064-.4541-.83984s-.77832-.26855-1.42578-.26855h-.62011v-.93262h.62988c1.13965 0 1.70898-.39404 1.70898-1.18164 0-.30615-.09961-.54199-.29785-.70801s-.49121-.24902-.8789-.24902c-.27051 0-.53027.03809-.78125.11475s-.54688.22558-.88868.44677l-.56152-.80078c.6543-.48193 1.41406-.72266 2.28027-.72266.71973 0 1.28125.15479 1.68457.46387s.60547.73584.60547 1.2793c0 .45264-.13184.8291-.39551 1.13037s-.63477.50391-1.11328.60791v.03906c.57324.07178 1.00293.25 1.28906.53467s.42969.66308.42969 1.13525zM8 0C3.58179 0 0 3.58173 0 8s3.58179 8 8 8 8-3.58173 8-8-3.58179-8-8-8zm0 15c-3.86597 0-7-3.13403-7-7 0-3.86603 3.13403-7 7-7s7 3.13397 7 7c0 3.86597-3.13403 7-7 7z" />
</svg>
    </HvIconBase>
)};

Priority3.propTypes = HvIconBase.propTypes;

export default Priority3;