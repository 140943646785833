
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Host = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Host" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M6.5809 15.0027H.00100617v-.9973H6.5798c.07488-.2102.19574-.4012.35367-.559.15794-.1578.34906-.2786.55954-.3536v-2.0794H.00003052L.0150311 0H16l-.014 11.0134H8.49305v2.0794c.21069.0748.40202.1955.56014.3534.15812.1578.27912.3488.35404.5592H15.986v.9973H9.40613c-.10329.2916-.29459.5441-.54758.7227-.25299.1786-.55517.2746-.86504.2746-.30986 0-.61204-.096-.86503-.2746-.25299-.1786-.44429-.4311-.54758-.7227zm.91308-.4987c0 .1323.05267.2591.14635.3526.09368.0935.2207.1461.35318.1461.13249 0 .25951-.0526.35319-.1461.09368-.0935.14635-.2203.14635-.3526.00022-.1272-.04833-.2497-.13562-.3424-.08729-.0927-.20669-.1486-.33392-.1562h-.05902c-.1274.0074-.24702.0631-.33453.1559-.0875.0927-.13619.2153-.13598.3427zM1.01605 9.9732H15.0029V5.98392H1.01605V9.9732zm0-4.9866H15.0029V.99732H1.01605V4.9866zM12.9538 8.47722V7.4799h.9991v.99732h-.9991zm-10.98967 0V7.4799h5.99438v.99732H1.96413zm10.98967-4.9866V2.4933h.9991v.99732h-.9991zm-10.98967 0V2.4933h5.99438v.99732H1.96413z" />
</svg>
    </HvIconBase>
)};

Host.propTypes = HvIconBase.propTypes;

export default Host;