
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Train = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Train" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M12 1.94H0v10.5h2.071a1.99 1.99 0 003.858 0h.142a1.99 1.99 0 003.858 0h.142a1.99 1.99 0 003.858 0H16v-6.5a4 4 0 00-4-4zm-7.154 10.5a.966.966 0 01-1.692 0 .89.89 0 010-1 .966.966 0 011.692 0 .89.89 0 010 1zm4 0a.966.966 0 01-1.692 0 .89.89 0 010-1 .966.966 0 011.692 0 .89.89 0 010 1zm4 0a.966.966 0 01-1.692 0 .89.89 0 010-1 .966.966 0 011.692 0 .89.89 0 010 1zm2.154-1h-1.071a1.99 1.99 0 00-3.858 0h-.142a1.99 1.99 0 00-3.858 0h-.142a1.99 1.99 0 00-3.858 0H1v-8.5h4V8h10zM15 7H6V2.94h6a3 3 0 013 3z" />
</svg>
    </HvIconBase>
)};

Train.propTypes = HvIconBase.propTypes;

export default Train;