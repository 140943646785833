
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Dislike = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Dislike" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M.00057 8.435l.973-6.215s.258-2.227 3.254-2.227h5.8a5.481 5.481 0 011.973.373v-.373h4v10h-4v-.667a3.128 3.128 0 00-2 2.912v3.755s-.15.014-.391.014c-1 0-3.591-.234-3.591-2.642l-.049-2.372h-3.663a2.49 2.49 0 01-2.306-2.558zm13 .558h2v-8h-2zm-12-.486a1.355 1.355 0 00.616 1.216 1.96111 1.96111 0 00.744.27h4.587l.02.979.05 2.373v.02a1.3 1.3 0 00.841 1.317 3.70009 3.70009 0 001.142.292v-2.736a3.963 3.963 0 011.968-3.491 5.54112 5.54112 0 011.032-.504v-6.55a.268.268 0 00-.1-.227 1.56 1.56 0 00-.573-.285 4.9742 4.9742 0 00-1.303-.188h-5.8a2.43 2.43 0 00-1.916.674 1.549 1.549 0 00-.345.676v.031z" />
</svg>
    </HvIconBase>
)};

Dislike.propTypes = HvIconBase.propTypes;

export default Dislike;