
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleWalkingLowerClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleWalkingLowerClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.667 22.00369h1.3c.019-.32887.029-.66275.029-.99963 0-4.3324-1.6-8.068-3.915-9.86035A5.99035 5.99035 0 0021.99 6.0096H24V4.01034h-2.343a6.00073 6.00073 0 00-11.321 0H8V6.0096h2a5.97537 5.97537 0 002.909 5.13411C10.6 12.937 9 16.67266 9 21.00406c0 .33588.01.66976.028.99963h1.305L7 32h6l3-8.99668L19 32h6zM16 2.01108a3.98229 3.98229 0 013.445 1.99926h-6.89A3.98229 3.98229 0 0116 2.01108zM12 6.0096h8a4 4 0 01-8 0zm-.967 13.99483a12.32715 12.32715 0 011.7-5.69789c1.27-1.99926 2.588-2.29915 3.261-2.29915s1.991.29989 3.261 2.29915a12.339 12.339 0 011.7 5.69789z" />
</svg>
    </HvIconBase>
)};

MaleWalkingLowerClothesHat.propTypes = HvIconBase.propTypes;

export default MaleWalkingLowerClothesHat;