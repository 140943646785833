
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const DislikeSelected = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="DislikeSelected" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M10.027 0h-5.8c-3 0-3.254 2.227-3.254 2.227L0 8.442A2.491 2.491 0 002.306 11h3.663l.049 2.372c0 2.408 2.591 2.642 3.591 2.642C9.85 16.014 10 16 10 16v-3.755c0-1.671 1.127-2.511 2-2.245V.373A5.481 5.481 0 0010.027 0zM13 0h3v10h-3z" />
</svg>
    </HvIconBase>
)};

DislikeSelected.propTypes = HvIconBase.propTypes;

export default DislikeSelected;