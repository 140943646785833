
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Water = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Water" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  stroke="#414141" strokeMiterlimit={10} strokeWidth={3} d="M-6.5-6.5h29v29h-29z" />
  <g fill={colorArray[1]} className="color1" >
    <path d="M8 0S2 6.687 2 10a6 6 0 0012 0C14 6.687 8 0 8 0zm0 15a5 5 0 01-5-5c0-.65308.365-2.25708 2.812-5.65894C6.622 3.21484 7.436 2.208 8 1.53394c.569.677 1.389 1.69213 2.2 2.821C11.244 5.812 13 8.481 13 10a5 5 0 01-5 5z" />
    <path d="M10.562 8.53809l-.739.73877a2 2 0 01-2.646 2.646l-.739.73926q.064.03882.132.07495a3.00008 3.00008 0 004.065-4.072c-.023-.043-.047-.08499-.073-.12698z" />
  </g>
</svg>
    </HvIconBase>
)};

Water.propTypes = HvIconBase.propTypes;

export default Water;