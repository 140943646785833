
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleWalking = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleWalking" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22.971 20.004c-.223-3.89869-1.746-7.20958-3.88-8.864a6.00089 6.00089 0 10-6.181 0c-2.135 1.65445-3.657 4.96534-3.881 8.864L9 28.00134h1.332L9 32h6l1-2.999L17 32h6l-1.333-3.99866H23zM12 6.00873a4 4 0 114 3.99865 3.99933 3.99933 0 01-4-3.99865zM14.892 26.002l-.455 1.36655-.878 2.63211h-1.784l.456-1.36654.878-2.63212H11l.025-5.883a12.02112 12.02112 0 011.884-6.077A4.17306 4.17306 0 0116 12.00671a4.1671 4.1671 0 013.089 2.03529 12.02209 12.02209 0 011.885 6.077l.025.43985V26.002zm3.55 3.99866l-.667-1.99933h1.784l.666 1.99933z" />
</svg>
    </HvIconBase>
)};

FemaleWalking.propTypes = HvIconBase.propTypes;

export default FemaleWalking;