
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 20, height: 20 };
    default:
    case "S":
      return { width: 24, height: 24 };
    case "M":
      return { width: 40, height: 40 };
    case "L":
      return { width: 104, height: 104 };
  }
};

const Canceled = ({
  color,
  iconSize = "S",
  viewbox = "0 0 24 24",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1,"none",theme.palette.atmo2];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Canceled" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <circle cx={12} cy={12} r={12} fill={colorArray[0]} className="color0"  />
  <g transform="translate(-10 -10.05)">
    <circle cx={22} cy="22.05" r={6} fill={colorArray[1]} className="color1"  />
    <path fill={colorArray[2]} className="color2"  d="M22 16.05c-3.31371 0-6 2.68629-6 6s2.68629 6 6 6 6-2.68629 6-6c-.00331-3.31234-2.68766-5.99669-6-6zm4 6c-.00391.70703-.19729 1.40008-.56 2.007l-5.447-5.447c.60692-.36271 1.29997-.55609 2.007-.56 2.20914 0 4 1.79086 4 4zm-8 0c.0037-.71683.2021-1.41918.574-2.032l5.458 5.458c-.61282.3719-1.31517.5703-2.032.574-2.20914 0-4-1.79086-4-4z" />
  </g>
</svg>
    </HvIconBase>
)};

Canceled.propTypes = HvIconBase.propTypes;

export default Canceled;