
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Bold = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Bold" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M4 2.0053h3.56a6.81 6.81 0 013.58.72 2.523 2.523 0 011.1 2.28 2.816 2.816 0 01-.54 1.75 2.428 2.428 0 01-1.56.88v.08a3.088 3.088 0 011.86.94 2.842 2.842 0 01.6 1.9 3.053 3.053 0 01-1.13 2.53 4.9 4.9 0 01-3.14.92H4zm1.96 4.95h1.89a3.217 3.217 0 001.8-.39 1.52 1.52 0 00.57-1.33 1.322 1.322 0 00-.61-1.22 3.92494 3.92494 0 00-1.94-.38h-1.7zm0 1.59v3.8h2.09a3.053 3.053 0 001.86-.47 1.736 1.736 0 00.63-1.49 1.577 1.577 0 00-.64-1.39 3.4 3.4 0 00-1.94-.45z" />
</svg>
    </HvIconBase>
)};

Bold.propTypes = HvIconBase.propTypes;

export default Bold;