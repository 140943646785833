
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleRunningUpperClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleRunningUpperClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.666 4.01421a6.001 6.001 0 00-11.324 0H8v1.999h2.009a5.9757 5.9757 0 002.909 5.13035l-.006.006A7.83474 7.83474 0 0011.34 12.819l-.021.03a11.26 11.26 0 00-1.209 2.23628c-.016.037-.029.075-.044.112-.146.36688-.277.74876-.4 1.14263-.018.061-.038.119-.055.17994-.123.42887-.23.87072-.317 1.32758-.011.056-.018.114-.028.17094-.07.39088-.125.79075-.168 1.19962-.012.118-.024.23592-.034.35589-.039.46984-.066.94569-.066 1.43154 0 .15895.009.3129.013.46984 0 .177 0 .35489.015.52984L4 22.04306v5.959l10.08827-.03529 1.95814-5.22106 1.11778 3.35164L15.0971 32H21l1.966-6.0969-1.3-3.898h1.305c.016-.28486.022-.57571.029-.86756v-.18591c0-.46076-.025-.91253-.062-1.35831-.011-.13393-.025-.26686-.039-.39979q-.06-.57571-.158-1.13043c-.013-.076-.023-.15392-.037-.22988-.086-.44578-.19-.87656-.31-1.29934-.021-.076-.046-.14993-.069-.22589q-.168-.55871-.375-1.08345c-.021-.052-.039-.09995-.06-.15592a11.28321 11.28321 0 00-1.19-2.20688l-.038-.054a7.81673 7.81673 0 00-1.562-1.65615l-.009-.009A5.98734 5.98734 0 0022 6.0132h2v-1.999zM16 2.01523a3.98238 3.98238 0 013.445 1.999h-6.89A3.98238 3.98238 0 0116 2.01523zm-3.4008 23.98782L6 26.02324v-2.01918l5-.02726-.01-1.97172h3.10357zm6.9598-3.998l1.3 3.905-1.316 4.09095h-1.724l1.117-3.19138.226-.64468-.216-.64767-1.171-3.51221zM16 10.01117a3.999 3.999 0 01-4-3.998h8a3.999 3.999 0 01-4 3.998z" />
</svg>
    </HvIconBase>
)};

MaleRunningUpperClothesHat.propTypes = HvIconBase.propTypes;

export default MaleRunningUpperClothesHat;