
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleRunningUpperClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleRunningUpperClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22.9939 21.00378c0-.29089-.013-.57775-.028-.86364v-.136c0-.019-.005-.03809-.006-.057-.026-.43591-.07-.86364-.128-1.28357-.016-.111-.036-.21789-.054-.32788-.051-.31787-.109-.63086-.177-.93774q-.04092-.18275-.084-.36279-.12-.486-.268-.94776c-.023-.07092-.042-.1449-.066-.21484q-.193-.57789-.429-1.1106c-.019-.043-.04-.083-.06-.126-.14-.30884-.29-.59986-.449-.8877l-.12-.20691q-.24307-.4118-.51-.78381c-.027-.03687-.052-.07593-.078-.11084a7.787 7.787 0 00-1.4-1.45557c-.019-.015-.03595-.036-.056-.051a6.0009 6.0009 0 10-6.18 0c-.018.014-.035.034-.053.04809a7.78245 7.78245 0 00-1.4 1.46338c-.022.029-.042.06006-.063.09009q-.278.38178-.529.81067c-.035.06091-.071.122-.106.184-.167.2998-.325.60571-.47.92969-.014.02893-.028.05688-.041.08593q-.24.54382-.438 1.13159c-.018.054-.033.111-.05.165q-.157.49072-.284 1.0066-.039.15892-.075.3208-.1095.49035-.188.99975c-.015.09094-.032.17993-.045.27295-.06.42871-.105.8667-.132 1.3125v.20691c-.014.27686-.027.55383-.027.83569 0 .11695.007.22986.00995.34485L9 22.0144l-5 .02405v5.99793l12.395-.035L14.99493 32h6l1.333-3.99866h.667V22c.01197-.25391-.00103-.73828-.00103-.99622zM11.995 6.009a4 4 0 113.99993 3.99854A3.99934 3.99934 0 0111.995 6.009zm7.55792 23.9917H17.814l.66095-1.88745 1.722-.043zM5.995 26.03v-1.99924l5-.02807v-1.99927h9.98193l.018 3.99866z" />
</svg>
    </HvIconBase>
)};

FemaleRunningUpperClothes.propTypes = HvIconBase.propTypes;

export default FemaleRunningUpperClothes;