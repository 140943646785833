
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Tractor = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Tractor" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M14 9.053V7h-2V5h1V4h-2v3H9.642L7.861 3l-.446-1H2.5v6.05a3 3 0 103.084 4.45h5.627A2.5 2.5 0 1014 9.053zM3 13a2 2 0 112-2 2 2 0 01-2 2zm8-1.5H5.95A3.059 3.059 0 006 11a2.994 2.994 0 00-2.5-2.95V3h3.266l1.781 4 .446 1H13v1.05a2.5 2.5 0 00-2 2.45zm2.5 1.5a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5z" />
</svg>
    </HvIconBase>
)};

Tractor.propTypes = HvIconBase.propTypes;

export default Tractor;