
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FoggyDay = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FoggyDay" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <g fill={colorArray[0]} className="color0" >
    <path d="M7.5 0h1v3h-1zM13 7.5h3v1h-3zm-13 0h3v1H0zm1.9850014357-4.8026399184l.7069957004-.7072178445 2.1216535336 2.1209871011-.7069957003.7072178446zm9.1972910969 1.4090187586L13.3035388268 1.98498445l.7071314634.7070820981-2.1212462942 2.1213943903zM8.432 11.985C6.27 11 3.821 9.889 0 11.527l.373.924c3.436-1.476 5.588-.5 7.669.449a9.2 9.2 0 003.82 1.1A6.8 6.8 0 0016 12.391l-.573-.806c-2.964 2.233-4.743 1.424-6.995.4z" />
    <path d="M8.432 13.985C6.27 13 3.821 11.889 0 13.527l.373.924c3.436-1.476 5.588-.5 7.669.449a9.2 9.2 0 003.82 1.1A6.8 6.8 0 0016 14.391l-.573-.806c-2.964 2.233-4.743 1.424-6.995.4zm2.31-3.116A3.956 3.956 0 0012 8a4 4 0 10-8 0 3.672 3.672 0 00.08.7A9.122 9.122 0 000 9.527l.373.924c3.436-1.476 5.588-.5 7.669.449a9.213 9.213 0 003.82 1.1A6.8 6.8 0 0016 10.391l-.573-.806c-1.905 1.435-3.319 1.615-4.685 1.284zM9.619 10.5c-.387-.155-.779-.333-1.187-.518a13.9 13.9 0 00-3.3-1.159A2.878 2.878 0 015 8a3 3 0 114.619 2.5z" />
  </g>
</svg>
    </HvIconBase>
)};

FoggyDay.propTypes = HvIconBase.propTypes;

export default FoggyDay;