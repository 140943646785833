
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleWalkingUpperClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleWalkingUpperClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M23 28.00147V22c.012-.25391-.001-.73806-.001-.996 0-.29089-.013-.57778-.028-.86368v-.136c0-.019-.005-.038-.006-.057-.026-.43484-.07-.86068-.128-1.27953-.016-.116-.037-.22791-.056-.34287-.049-.31089-.106-.61678-.173-.91766-.028-.128-.057-.25491-.088-.38086q-.117-.47083-.26-.91867c-.025-.08-.047-.16294-.074-.24091q-.192-.56979-.424-1.0996c-.022-.051-.048-.1-.071-.14894a9.517 9.517 0 00-.437-.86368c-.042-.075-.085-.14795-.128-.22092q-.237-.39985-.5-.76572c-.03-.041-.058-.086-.088-.126a7.7622 7.7622 0 00-1.387-1.44746c-.021-.016-.04-.039-.061-.055a6.001 6.001 0 10-6.181 0c-.018.014-.035.034-.053.048a7.78027 7.78027 0 00-1.4 1.46346c-.022.029-.042.06-.063.09q-.278.38185-.529.8107c-.035.061-.071.122-.106.18393-.167.29989-.325.60578-.47.92966-.014.029-.028.057-.041.086q-.24.54381-.438 1.13159c-.018.054-.033.111-.05.16494q-.157.49082-.284 1.00663-.039.15894-.075.32088c-.073.32688-.135.65976-.188.99963-.015.091-.032.17994-.045.2729-.06.42885-.105.86668-.132 1.31252v.143c-.016.29989-.029.59978-.029.89967 0 .2689-.018.73006-.006.997v6.00147h1.332L9 32h6l1-2.9989L17 32h6l-1.333-3.99853zM12 6.00954a4 4 0 114 3.99853 3.99926 3.99926 0 01-4-3.99853zm6.442 23.99119l-.667-1.99926h1.784l.666 1.99926zm-3.55-3.99853l-.455 1.3665-.878 2.632h-1.784l.456-1.3665.878-2.632H11l.017-3.99853H21v3.99853z" />
</svg>
    </HvIconBase>
)};

FemaleWalkingUpperClothes.propTypes = HvIconBase.propTypes;

export default FemaleWalkingUpperClothes;