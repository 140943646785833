
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleRunningHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleRunningHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22 6.00281h2V4.003h-2.342a6.00021 6.00021 0 00-11.315 0H8v1.99981h1.9654a6.00233 6.00233 0 002.89322 5.12856C10.73593 12.78526 9.22271 16.096 9 19.99343l-.02883 2.00765L4 22.02507v5.97536l12.50315-.03327L15.09151 32H21l1.333-3.99957H23l-.029-7.99913c-.224-3.89958-1.746-7.21122-3.881-8.866A5.99924 5.99924 0 0022 6.00281zm-6-3.99957A4.00008 4.00008 0 0119.464 4.003h-6.927A4.0001 4.0001 0 0116 2.00324zm3.559 27.997h-1.74l.661-1.8878 1.722-.043zM21 20.08529l.02605 5.91536L6 26.029v-2.02813l5-.028v-2.99968l.02506-.8859A11.99866 11.99866 0 0112.915 14.009a4.18445 4.18445 0 013.098-2.03483 4.18345 4.18345 0 013.09809 2.03283A11.98861 11.98861 0 0121 20.08529zm-5-10.08291a3.99979 3.99979 0 01-4-3.99957h8a4.00478 4.00478 0 01-4 3.99957z" />
</svg>
    </HvIconBase>
)};

FemaleRunningHat.propTypes = HvIconBase.propTypes;

export default FemaleRunningHat;