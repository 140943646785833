
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Layers = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Layers" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13.17286 6.59519l2.8 1.4zm-7 4.5l-.9-.4 2.7 1.3 1.9-1-.1.1-1.8.9zm9.8-3.1l-3-1.5zm-13-1.5l-3 1.5zm2.6 4.3l2.4 1.2 1.7-.8-1.7.8zm7.4-4.3l-1.1.5 1.9 1-5.8 2.9-5.8-2.9 1.9-1-1.1-.5-3 1.5 5.6 2.8 2.4 1.2 1.7-.8 6.3-3.2zm-10 0l1.1.5zm10 0l-1.1.5zm3 5.5l-3-1.5zm-13-1.5l-3 1.5zm2.6 4.3l2.4 1.2 1.7-.8-1.7.8zm7.4-4.3l-1.1.5 1.9.9-5.8 3-5.8-2.9 1.9-1-1.1-.5-3 1.5 5.6 2.8 2.4 1.2 1.7-.8 6.3-3.2zm-10 0l1.1.5zm10 0l-1.1.5zm.79149-6.46L8.0368 6.93432 2.30917 4.03511 8.03671 1.136l5.72764 2.8992M8.0368.00481L.04646 4.03529 8.0368 8.06576l7.99034-4.03047z" />
</svg>
    </HvIconBase>
)};

Layers.propTypes = HvIconBase.propTypes;

export default Layers;