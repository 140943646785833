
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleRunningLowerClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleRunningLowerClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22.971 20.00414c-.224-3.89865-1.746-7.20951-3.881-8.86394a6.00092 6.00092 0 10-6.18 0c-2.135 1.65443-3.657 4.96529-3.881 8.86394H9v2.01031l-5 .024v5.99793l12.4-.035L15 32h6l1.333-3.99862H23v-7.99724zM12 6.009a4 4 0 114 3.99862A3.99931 3.99931 0 0112 6.009zm.91 8.03223A4.16909 4.16909 0 0116 12.0069a4.16809 4.16809 0 013.09 2.0353 12.01386 12.01386 0 011.873 5.96194h-9.927a12.01889 12.01889 0 011.874-5.96294z" />
</svg>
    </HvIconBase>
)};

FemaleRunningLowerClothes.propTypes = HvIconBase.propTypes;

export default FemaleRunningLowerClothes;