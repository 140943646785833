
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Heart = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Heart" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M12.02852 1.00363a3.39816 3.39816 0 00-2.07834.59177c-.4539.34085-1.42259 1.26078-1.93543 1.76065-.51382-.49694-1.48154-1.4198-1.93635-1.76065A3.39845 3.39845 0 004 1.00363a4.02483 4.02483 0 00-2.959 6.70724L8.01475 15l6.98587-7.29816a4.025 4.025 0 00-2.9721-6.69821zM14.3018 7.083l-6.28705 6.5703L1.73971 7.096A3.128 3.128 0 01.958 5.02151 3.075 3.075 0 014 1.92234v.002a2.48726 2.48726 0 011.50558.39992c.57178.429 2.50917 2.3324 2.50917 2.3324s1.93641-1.90348 2.50825-2.3344a2.48089 2.48089 0 011.50552-.39992 3.07416 3.07416 0 013.04209 3.09917A3.11859 3.11859 0 0114.3018 7.083z" style={{isolation: 'isolate'}} />
</svg>
    </HvIconBase>
)};

Heart.propTypes = HvIconBase.propTypes;

export default Heart;