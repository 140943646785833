
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Rain = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Rain" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M5.869 15.755l.973.245 1.667-6.755L7.537 9l-1.668 6.755z" />
  <path fill={colorArray[0]} className="color0"  d="M13.984 5.334A3 3 0 0010.3 2.09 4.536 4.536 0 004.084.679a4.418 4.418 0 00-2.078 3.828v.035a4.01 4.01 0 00-1.762 4.84 4.07 4.07 0 003.543 2.6l-.482 1.96.972.245 1.217-4.942L4.522 9l-.5 2.007h-.017a3.007 3.007 0 01-2.959-2.483 3.092 3.092 0 011.566-3.178l.394-.227v-.612a3.507 3.507 0 012.818-3.434 3.6 3.6 0 013.7 1.669l.314.489.692-.165a1.979 1.979 0 01.69-.044 2 2 0 011.771 2.208l-.077.711.647.3a2.507 2.507 0 011.288 3.145 2.58 2.58 0 01-2.459 1.621h-1.268l.434-1.762L10.584 9l-1.217 4.939.972.245.537-2.177H12.4a3.558 3.558 0 002.739-1.19 3.508 3.508 0 00-1.155-5.483z" />
</svg>
    </HvIconBase>
)};

Rain.propTypes = HvIconBase.propTypes;

export default Rain;