
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Poo = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Poo" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  stroke="#414141" strokeMiterlimit={10} strokeWidth={3} d="M-7.5-6.5h29v29h-29z" />
  <path fill={colorArray[1]} className="color1"  d="M13.5 10.01855h-.013a2.471 2.471 0 00.513-1.5 2.49989 2.49989 0 00-2.5-2.5h-1.278a2.98236 2.98236 0 00.778-2v-3H8a3.10578 3.10578 0 00-3 3 2.98267 2.98267 0 00.778 2H4.5a2.49993 2.49993 0 00-2.5 2.5 2.47138 2.47138 0 00.513 1.5H2.5a2.5 2.5 0 000 5h11a2.5 2.5 0 000-5zm-5.5-8h2v2a2 2 0 11-4 0 2.10883 2.10883 0 012-2zm-3.5 5h7a1.5 1.5 0 110 3h-7a1.5 1.5 0 010-3zm9 7h-11a1.5 1.5 0 010-3h11a1.5 1.5 0 010 3z" />
</svg>
    </HvIconBase>
)};

Poo.propTypes = HvIconBase.propTypes;

export default Poo;