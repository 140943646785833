
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FoggyNight = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FoggyNight" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <g fill={colorArray[0]} className="color0" >
    <path d="M8.432 11.933c-2.161-1-4.61-2.123-8.431-.463l.373.935c3.436-1.495 5.588-.506 7.668.455a9.113 9.113 0 003.82 1.114A6.754 6.754 0 0016 12.345l-.573-.817c-2.964 2.262-4.743 1.443-6.995.405z" />
    <path d="M8.432 13.959c-2.161-1-4.61-2.123-8.431-.464l.373.936c3.436-1.495 5.588-.506 7.668.455A9.113 9.113 0 0011.862 16 6.749 6.749 0 0016 14.37l-.573-.816c-2.964 2.262-4.743 1.446-6.995.405zM15.426 9.5c-2.919 2.229-4.689 1.466-6.894.451A7.128 7.128 0 016.026.267c.052-.09.107-.179.163-.267A8.081 8.081 0 000 7.9a7.85 7.85 0 00.159 1.48c-.054.023-.1.041-.159.064l.373.936c3.436-1.5 5.588-.507 7.668.454a9.114 9.114 0 003.82 1.115A6.754 6.754 0 0016 10.319zM2.544 3.457a7.04 7.04 0 011.768-1.583 8.123 8.123 0 001.521 7.015 8.055 8.055 0 00-4.733.144A6.969 6.969 0 011 7.9a7.16 7.16 0 011.544-4.443z" />
  </g>
</svg>
    </HvIconBase>
)};

FoggyNight.propTypes = HvIconBase.propTypes;

export default FoggyNight;