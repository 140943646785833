
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Link = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Link" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M15.0797 2.32061a3.07 3.07 0 00-4.242 0l-2.26 2.26a3.076 3.076 0 00-3.4.568l-4.24 4.243a3 3 0 004.20849 4.27652l.03451-.03452 2.25-2.25a2.962 2.962 0 003.407-.578l4.242-4.243a3 3 0 000-4.242zm-6.364 6.364l-1.414 1.414a2 2 0 01-.00058-2.82842l.00058-.00058 1.415-1.413a2 2 0 01-.001 2.828zm-4.242 4.242a2.048 2.048 0 01-2.829 0 2 2 0 010-2.828l4.243-4.243a1.943 1.943 0 011.926-.511l-1.219 1.218a3 3 0 00-.00036 4.24264l.00036.00036zm9.9-7.071l-4.242 4.243a2.033 2.033 0 01-1.914.5l1.206-1.206a3 3 0 000-4.242l2.121-2.122a2.047 2.047 0 012.828 0 2 2 0 010 2.828z" />
</svg>
    </HvIconBase>
)};

Link.propTypes = HvIconBase.propTypes;

export default Link;