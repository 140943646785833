
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Severity1 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Severity1" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8.24023 8.77197c.11426.23779.1709.51758.1709.83984 0 .63477-.22949 1.13281-.68848 1.49414s-1.0918.54199-1.89941.54199-1.46777-.12549-1.98242-.37598v-1.10352c.3252.15283.67188.27344 1.03711.36133.36719.08789.70801.13184 1.02344.13184.46191 0 .80273-.08789 1.02344-.26367.21875-.17578.3291-.41162.3291-.70801 0-.26709-.10059-.49316-.30273-.67871s-.61816-.40527-1.25-.65918c-.65138-.26366-1.11036-.56493-1.37696-.90331s-.40039-.74561-.40039-1.2207c0-.5957.21191-1.06445.63477-1.40625s.99121-.5127 1.7041-.5127c.68359 0 1.36426.1499 2.04102.44922l-.3711.95215c-.63477-.26709-1.20117-.40039-1.69922-.40039-.37793 0-.66406.08203-.85938.24658s-.29297.38184-.29297.65186c0 .18555.03906.34424.11719.47607s.20703.25635.38574.37354.50098.27197.9668.46387c.52441.21826.9082.42139 1.15234.61035s.42286.40185.53711.63964zm.86231-2.70996l.57617.72754c.62207-.50439.9873-.8042 1.0957-.89844.10938-.09424.20117-.18066.27637-.25879-.02637.32227-.03906.7583-.03906 1.30859v4.60938h1.14746V4.41162h-.95703L9.10254 6.06201zM16 8c0 4.41827-3.58179 8-8 8s-8-3.58173-8-8 3.58179-8 8-8 8 3.58173 8 8zm-1 0c0-3.86603-3.13403-7-7-7S1 4.13397 1 8c0 3.86597 3.13403 7 7 7s7-3.13403 7-7z" />
</svg>
    </HvIconBase>
)};

Severity1.propTypes = HvIconBase.propTypes;

export default Severity1;