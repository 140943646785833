
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleRunningUpperClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleRunningUpperClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.9911 6.01088h2.0099V4.01172h-2.34188a6.00039 6.00039 0 00-11.32143 0H8.0018v1.99916h1.9999a5.97506 5.97506 0 002.89985 5.13085c-.018.014-.035.034-.053.048a7.7981 7.7981 0 00-1.39993 1.46339c-.022.029-.042.06-.063.09q-.277.38184-.529.81066c-.035.061-.071.122-.106.18393-.167.29987-.325.60574-.47.92961-.014.029-.028.057-.041.086q-.24.54378-.438 1.13153c-.018.054-.033.11095-.05.16493q-.158.4908-.284 1.00658-.039.15894-.075.32086c-.073.32687-.135.65973-.188.99959-.015.091-.032.17992-.045.27288-.06.42882-.1.86664-.132 1.31245v.20686c-.014.27688-.027.55377-.027.83565 0 .11695.007.2299.01.34485l-.01.66572-4.99975.024v5.99749l12.40038-.035L15.00145 32h5.9997l1.33293-3.99833h.667l-.023-6.23139c.012-.25389.022-.50778.022-.76568 0-.29088-.013-.57776-.028-.86364V20.005c0-.019-.005-.038-.006-.057-.026-.43582-.07-.86364-.128-1.28347-.016-.111-.036-.21791-.054-.32786-.051-.31787-.109-.63073-.177-.93761q-.041-.18291-.084-.36284-.12-.4858-.268-.94761c-.023-.071-.042-.14494-.066-.21491q-.193-.57775-.429-1.11053c-.019-.043-.04-.083-.06-.126-.14-.30887-.29-.59975-.449-.88763l-.12-.20691q-.243-.41184-.51-.78367c-.027-.037-.052-.076-.078-.111a7.816 7.816 0 00-1.39993-1.45539c-.019-.015-.036-.036-.056-.051a5.989 5.989 0 002.90395-5.13069zm-5.9897-3.99832a3.98205 3.98205 0 013.44483 1.99916h-6.88966a3.98205 3.98205 0 013.44483-1.99916zm3.55782 27.98828h-1.73891l.661-1.88721 1.72191-.043zm1.42393-7.99665l.018 3.99832-14.99925.028v-1.99917l4.99975-.028v-1.99915zm-4.98175-11.995a3.99906 3.99906 0 01-3.9998-3.99833h7.9996a3.99906 3.99906 0 01-3.9998 3.99835z" />
</svg>
    </HvIconBase>
)};

FemaleRunningUpperClothesHat.propTypes = HvIconBase.propTypes;

export default FemaleRunningUpperClothesHat;