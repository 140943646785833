
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const BubbleChart = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="BubbleChart" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 15v1H0V0h1v15zM5 12.5A1.5 1.5 0 103.5 14 1.5 1.5 0 005 12.5zm-1 0a.5.5 0 11-.5-.5.5.5 0 01.5.5zm6-9A1.5 1.5 0 108.5 5 1.5 1.5 0 0010 3.5zm-1 0a.5.5 0 11-.5-.5.5.5 0 01.5.5zM16 2a2 2 0 10-2 2 2 2 0 002-2zm-1 0a1 1 0 11-1-1 1 1 0 011 1zm-1 5a2 2 0 10-2 2 2 2 0 002-2zm-1 0a1 1 0 11-1-1 1 1 0 011 1zm-2 4.5A1.5 1.5 0 109.5 13a1.5 1.5 0 001.5-1.5zm-1 0a.5.5 0 11-.5-.5.5.5 0 01.5.5zM8 8a2 2 0 10-2 2 2 2 0 002-2zM7 8a1 1 0 11-1-1 1 1 0 011 1z" />
</svg>
    </HvIconBase>
)};

BubbleChart.propTypes = HvIconBase.propTypes;

export default BubbleChart;