
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleStandingLowerClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleStandingLowerClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M19.5 10.87016a6.001 6.001 0 10-7 0C9.268 12.5386 7 16.44827 7 21.00373c0 .33588.013.66977.037.99966H12V32h8v-9.99661h4.963c.024-.32989.037-.66378.037-.99966 0-4.55546-2.268-8.46513-5.5-10.13357zM12 6.00881a4 4 0 114 3.99865 3.99932 3.99932 0 01-4-3.99865zM9.043 20.00407a10.03465 10.03465 0 012.141-5.51113A6.25264 6.25264 0 0116 12.00678a6.25065 6.25065 0 014.816 2.48816 10.02861 10.02861 0 012.141 5.50913z" />
</svg>
    </HvIconBase>
)};

MaleStandingLowerClothes.propTypes = HvIconBase.propTypes;

export default MaleStandingLowerClothes;