
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const LightShowerDay = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="LightShowerDay" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M9.5 0h1v2.01h-1zM14 5.53h2v1h-2zM7.528 2.832L6.114 1.41l-.707.711 1.414 1.422.707-.711zm7.069-.71l-.707-.711-1.414 1.422.707.711 1.414-1.422zm-7.321 9.79l.97.244.241-.976-.969-.243-.242.975zm-.973 3.893l.982.195.233-.92-.97-.243-.245.968zm.488-1.942l.97.243.242-.975-.97-.244-.242.976zm1.878.487l.97.244.242-.976-.97-.243-.242.975zm1.818-3.17l-.97-.243-.242.975.97.244.242-.976zM4.45 14.35l.969.244.242-.976-.969-.243-.242.975zm.606-2.438l.969.244.242-.976-.97-.243-.241.975z" />
  <path fill={colorArray[0]} className="color0"  d="M13 9.1v-.05a2.5 2.5 0 00-.455-1.439A2.993 2.993 0 107.135 5.2a3.974 3.974 0 00-4.958 2.675c-.031.1-.057.2-.079.307A3.018 3.018 0 003 14.075h2v-1.006H3a2.012 2.012 0 01-.6-3.927l.551-.176.128-.566A3.021 3.021 0 016 6.032a3.175 3.175 0 012.5 1.349l.5.756.383-.2.416-.22a1.473 1.473 0 011.334-.028 1.5 1.5 0 01.71.7 1.476 1.476 0 01.157.659v.021l-.025.847.825.167a1.509 1.509 0 01-.3 2.986H9.822l-.4 1.006H12.5A2.515 2.515 0 0013 9.1zm-1.206-2.194a2.462 2.462 0 00-2.458-.082A4.04 4.04 0 008.053 5.6a2 2 0 113.741 1.3z" />
</svg>
    </HvIconBase>
)};

LightShowerDay.propTypes = HvIconBase.propTypes;

export default LightShowerDay;