
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const WorldGlobe = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="WorldGlobe" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8 0a8 8 0 108 8 8 8 0 00-8-8zm6 4.5h-1.5a8.4909 8.4909 0 00-1.3-2.7A6.10022 6.10022 0 0114 4.5zm-5.5 1h3.2a10.56744 10.56744 0 010 5H8.5zm-1 5H4.3a10.56744 10.56744 0 010-5h3.2zm3.9-6H8.5V1.1c1.2.2 2.3 1.6 2.9 3.4zM7.5 1.1v3.4H4.6c.6-1.8 1.7-3.2 2.9-3.4zm-2.7.7a7.54041 7.54041 0 00-1.3 2.7H2a6.10022 6.10022 0 012.8-2.7zM1 8a6.9641 6.9641 0 01.5-2.5h1.8A10.14574 10.14574 0 003.1 8a19.47457 19.47457 0 00.2 2.5H1.5A6.9641 6.9641 0 011 8zm1 3.5h1.5a8.4909 8.4909 0 001.3 2.7A6.10022 6.10022 0 012 11.5zm2.6 0h2.9v3.4c-1.2-.2-2.3-1.6-2.9-3.4zm3.9 3.4v-3.4h2.9c-.6 1.8-1.7 3.2-2.9 3.4zm2.7-.7a7.54041 7.54041 0 001.3-2.7H14a6.10022 6.10022 0 01-2.8 2.7zm1.6-3.7A10.14574 10.14574 0 0013 8a19.47637 19.47637 0 00-.2-2.5h1.8a6.49988 6.49988 0 010 5z" />
</svg>
    </HvIconBase>
)};

WorldGlobe.propTypes = HvIconBase.propTypes;

export default WorldGlobe;