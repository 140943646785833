
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const LogActivity = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="LogActivity" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M8.20001 15.143l.58496-.812c.65574.4375 1.42673.6704 2.21503.669 1.0609 0 2.0783-.4214 2.8284-1.1716C14.5786 13.0783 15 12.0609 15 11c0-1.06087-.4214-2.07828-1.1716-2.82843C13.0783 7.42142 12.0609 7 11 7c-.9596.00071-1.88701.3464-2.61298.974-.56977.49201-.98945 1.13454-1.21099 1.854l1.11798-.69999.52996.84799-1.69995 1.059-.84802.53-.53003-.848-1.05896-1.7.84796-.53.67102 1.074c.27068-.90267.7906-1.71065 1.5-2.331C8.61829 6.43376 9.7891 5.99658 11 6c1.3256.00159 2.5964.52888 3.5338 1.46622C15.4711 8.40355 15.9984 9.6744 16 11c-.0016 1.3256-.5289 2.5964-1.4662 3.5338C13.5964 15.4711 12.3256 15.9984 11 16c-.9982.002-1.9739-.2966-2.79999-.857zM2 7V6h2v1H2zM0 7V6h1v1H0zm2-2V4h4v1H2zM0 5V4h1v1H0zm2-2V2h10v1H2zM0 3V2h1v1H0zm2-2V0h10v1H2zM0 1V0h1v1H0z" />
</svg>
    </HvIconBase>
)};

LogActivity.propTypes = HvIconBase.propTypes;

export default LogActivity;