
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleRunningLowerClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleRunningLowerClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.994 6.01088H24V4.01172h-2.338a6.00079 6.00079 0 00-11.322 0H8v1.99916h2.006a5.975 5.975 0 002.9 5.13085C10.775 12.796 9.253 16.10666 9.029 20.005H9v2.01016l-5 .024v5.99749l12.4-.035L15 32h6l1.333-3.99833H23V20.005h-.029c-.224-3.89836-1.746-7.209-3.881-8.86329a5.987 5.987 0 002.904-5.13083zM16 2.01256a3.9823 3.9823 0 013.445 1.99916h-6.89A3.9823 3.9823 0 0116 2.01256zM20.963 20.005h-9.927a12.0144 12.0144 0 011.874-5.9625A4.16907 4.16907 0 0116 12.00837a4.16811 4.16811 0 013.09 2.03515 12.01237 12.01237 0 011.873 5.96148zM16 10.00921a3.99916 3.99916 0 01-4-3.99833h8a3.99916 3.99916 0 01-4 3.99833z" />
</svg>
    </HvIconBase>
)};

FemaleRunningLowerClothesHat.propTypes = HvIconBase.propTypes;

export default FemaleRunningLowerClothesHat;