
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const BlastholeDrill = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="BlastholeDrill" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 6.86026h-1V0L8.98978 6.12793 4.70664 1.76045H1.99955V8.0679a3.06871 3.06871 0 00-1.82873 3.89353A2.99728 2.99728 0 002.99958 14h5.50018a2.52571 2.52571 0 002.49259-2.55751 2.55221 2.55221 0 00-1.49256-2.32607V7.051l4.50015-4.58983v4.39909h-1v5.09981h1V14h1v-2.03993h1zM2.99958 2.78042h1.29305l4.20713 4.291v1.8288l-5.50018-1.02zm7.00023 8.66967a1.51522 1.51522 0 01-1.5 1.52994H2.99958a2.04071 2.04071 0 01-.08-4.07984l5.40017 1.0016.09.01836h.09a1.51522 1.51522 0 011.50006 1.52994zM15 10.94011h-1V7.88022h1zM2.5 10.5h1v1h-1zM8 11h1v1H8z" />
</svg>
    </HvIconBase>
)};

BlastholeDrill.propTypes = HvIconBase.propTypes;

export default BlastholeDrill;