
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleStandingUpperClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleStandingUpperClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M24.907 19.50751c-.013-.11793-.027-.23386-.044-.35079a12.70462 12.70462 0 00-.7-2.76034c-.039-.09994-.079-.19988-.121-.30681a11.37416 11.37416 0 00-1.3-2.34359c-.061-.08495-.122-.1679-.185-.24985a9.73076 9.73076 0 00-1.782-1.79892c-.079-.061-.158-.11993-.238-.17689a8.35725 8.35725 0 00-1.041-.65061 5.9783 5.9783 0 002.494-4.85409H24V4.01683h-2.344a5.99612 5.99612 0 00-11.319 0H8v1.99879h2a5.97832 5.97832 0 002.5 4.85409 8.43719 8.43719 0 00-1.039.64861c-.082.059-.162.11892-.242.18089a9.733 9.733 0 00-1.776 1.78792c-.065.08495-.129.1699-.192.25785a11.26532 11.26532 0 00-.711 1.09933 11.96326 11.96326 0 00-.576 1.22327c-.044.10693-.087.21487-.128.3238a12.39883 12.39883 0 00-.425 1.31721l-.006.025a12.77362 12.77362 0 00-.264 1.39915c-.018.12493-.033.24886-.047.37478A13.58362 13.58362 0 007 20.98862v.018c0 .3358.013.6696.037.9994H12V32h8v-9.994h4.964c.024-.3288.036-.6626.036-.9994a13.42039 13.42039 0 00-.093-1.49909zM16 2.018a3.9824 3.9824 0 013.445 1.9988h-6.89A3.9824 3.9824 0 0116 2.018zm2 27.9832h-4V22.006h4zm-2-19.988a3.9988 3.9988 0 01-4-3.9976h8a3.9988 3.9988 0 01-4 3.99762z" />
</svg>
    </HvIconBase>
)};

MaleStandingUpperClothesHat.propTypes = HvIconBase.propTypes;

export default MaleStandingUpperClothesHat;