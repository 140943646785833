
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleWalkingUpperClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleWalkingUpperClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.667 22.00367h1.3Q23 21.50986 23 21.004c0-.50682-.029-.99964-.072-1.49146-.006-.076-.014-.14994-.022-.22591-.047-.47483-.11-.94166-.194-1.39349v-.007a13.74142 13.74142 0 00-.336-1.40948c-.006-.022-.013-.043-.02-.065a13.57862 13.57862 0 00-.445-1.28552v-.007a9.44958 9.44958 0 00-2.823-3.97654 6.001 6.001 0 10-6.171 0c-.009.007-.017.016-.025.023a7.86143 7.86143 0 00-1.545 1.6414l-.045.064a11.23936 11.23936 0 00-1.193 2.21019c-.018.043-.033.087-.05.12995-.144.36187-.273.73673-.39 1.12559-.019.063-.039.124-.057.18693-.122.42884-.229.86968-.316 1.32451-.011.057-.018.117-.028.17394-.07.39086-.125.78971-.168 1.19956-.012.119-.024.23691-.034.35687C9.027 20.04839 9 20.52421 9 21.01c0 .159.009.31289.013.46983 0 .17694 0 .35487.015.52981h1.305L7 32h6l3-8.9967L19 32h6zM12 6.00954a4 4 0 114 3.99854 3.99927 3.99927 0 01-4-3.99854zm-.442 23.99119H9.774l2.456-7.36429.211-.63277h1.783zm6.217-7.99706h1.784l2.666 7.99706h-1.783z" />
</svg>
    </HvIconBase>
)};

MaleWalkingUpperClothes.propTypes = HvIconBase.propTypes;

export default MaleWalkingUpperClothes;