
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const People = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="People" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M12 6.9A3.50953 3.50953 0 106.5 4a1.26833 1.26833 0 00.1.6 1.18192 1.18192 0 00-.6-.1A3.543 3.543 0 002.5 8 3.646 3.646 0 004 10.9 5.426 5.426 0 00.5 16h1a4.5 4.5 0 019 0h1A5.574 5.574 0 008 10.9 3.49992 3.49992 0 009.5 8a2.66762 2.66762 0 000-.4c.454-.138.3-.1.5-.1a4.481 4.481 0 014.5 4.46191q.0001.01905 0 .03809h1A5.574 5.574 0 0012 6.9zM7.567 4.6A2.72059 2.72059 0 017.5 4 2.5 2.5 0 1110 6.5a2.92 2.92 0 01-.9-.2 3.15917 3.15917 0 00-1.4-1.4zM6 10.5a2.476 2.476 0 11.04825 0q-.02409.00023-.04825 0z" />
</svg>
    </HvIconBase>
)};

People.propTypes = HvIconBase.propTypes;

export default People;