
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 20, height: 20 };
    default:
    case "S":
      return { width: 24, height: 24 };
    case "M":
      return { width: 40, height: 40 };
    case "L":
      return { width: 104, height: 104 };
  }
};

const Level3Bad = ({
  color,
  iconSize = "S",
  viewbox = "0 0 24 24",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1,theme.palette.atmo2];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Level3Bad" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M12 0A12 12 0 110 12 12 12 0 0112 0z" />
  <path fill={colorArray[1]} className="color1"  d="M10.88 15.81h2.25v2.25h-2.25zm0-9.87h2.25v8h-2.25z" />
</svg>
    </HvIconBase>
)};

Level3Bad.propTypes = HvIconBase.propTypes;

export default Level3Bad;