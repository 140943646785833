
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleRunningHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleRunningHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22 6.00281h2V4.003h-2.343a6.00021 6.00021 0 00-11.315 0H8v1.99981h2a5.99924 5.99924 0 002.915 5.13345C10.6 12.92906 9 16.66666 9 21.00119c0 .337.01.67093.028.99989l-5.028.033v5.96635l10.08319-.03327 1.95715-5.22152 1.11721 3.3523L15.09151 32H21l1.971-6.09934-1.3-3.89958h1.3c.019-.33.029-.66393.029-.99989 0-4.33253-1.6-8.07013-3.915-9.86293A5.99923 5.99923 0 0022 6.00281zm-6-3.99956A4.00005 4.00005 0 0119.464 4.003h-6.926A4.00008 4.00008 0 0116 2.00325zm3.542 27.997H17.82l1.117-3.19266.226-.64493-.216-.64893-1.169-3.51262h1.784l1.3 3.90758zm-.30811-15.64887a12.18367 12.18367 0 011.69627 5.65h-6.0819l-.48619 1.29762-1.76007 4.70165L6 26.02561v-2.02474l5-.02479-.011-3.19469a12.54445 12.54445 0 011.73519-6.42013c1.26721-1.98244 2.58232-2.2798 3.25783-2.2798s1.98563.28844 3.25284 2.27088zM16 10.00238a3.99979 3.99979 0 01-4-3.99957h8a3.99979 3.99979 0 01-4 3.99957z" />
</svg>
    </HvIconBase>
)};

MaleRunningHat.propTypes = HvIconBase.propTypes;

export default MaleRunningHat;