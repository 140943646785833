
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleRunning = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleRunning" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M22.971 20.00414c-.224-3.89865-1.746-7.20951-3.881-8.86394a6.00092 6.00092 0 10-6.18 0c-2.135 1.65443-3.657 4.96529-3.881 8.86394L9 22.01445l-5 .024v5.99793l12.4-.035L15 32h6l1.333-3.99862H23zm-3.413 9.99655h-1.739l.661-1.88735 1.722-.043zM16 2.01035A3.99862 3.99862 0 1112 6.009a3.99931 3.99931 0 014-3.99865zM6 26.03006v-1.99931l5-.028v-2.999l.025-.8857a12.02188 12.02188 0 011.885-6.0769A4.17006 4.17006 0 0116 12.0069a4.17006 4.17006 0 013.09 2.0353 12.01189 12.01189 0 011.884 6.0769l.026 5.883z" />
</svg>
    </HvIconBase>
)};

FemaleRunning.propTypes = HvIconBase.propTypes;

export default FemaleRunning;