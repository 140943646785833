
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Severity5 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Severity5" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.59668 8.79346c.11426.23779.1709.51758.1709.83984 0 .63477-.22949 1.13281-.68848 1.49414s-1.0918.54199-1.89941.54199-1.46777-.12549-1.98242-.37598v-1.10352c.3252.15283.67188.27344 1.03711.36133.36719.08789.70801.13184 1.02344.13184.46191 0 .80273-.08789 1.02344-.26367.21875-.17578.3291-.41162.3291-.70801 0-.26709-.10059-.49316-.30273-.67871s-.61816-.40527-1.25-.65918c-.65138-.26367-1.11036-.56494-1.37697-.90332s-.40039-.74561-.40039-1.2207c0-.5957.21191-1.06445.63477-1.40625s.99121-.5127 1.7041-.5127c.68359 0 1.36426.1499 2.04102.44922l-.3711.95215c-.63476-.26709-1.20117-.40039-1.69922-.40039-.37793 0-.66406.08203-.85938.24658s-.29296.38184-.29296.65186c0 .18555.03906.34424.11719.47607s.20703.25635.38574.37354.50098.27197.9668.46387c.52441.21826.9082.42139 1.15234.61035s.42285.40185.53711.63965zm2.97168-1.67969c-.2373 0-.43848.01318-.60352.03906-.16406.02588-.30273.04883-.41699.06836l.16113-1.76758h2.7002v-1.0205H8.69824L8.4248 7.91943l.5127.30273c.16895-.03906.36621-.07422.59082-.10498s.41309-.04638.56641-.04638c1.08691 0 1.63086.42627 1.63086 1.2793 0 .4458-.13477.78467-.40527 1.01562s-.66406.34668-1.18164.34668c-.29297 0-.61035-.04053-.95215-.12207s-.64453-.19678-.9082-.34668v1.04004c.45605.25732 1.08203.38574 1.87988.38574.87891 0 1.55664-.21387 2.0332-.64209.47754-.42822.71582-1.02295.71582-1.78467 0-.65771-.20996-1.17676-.62988-1.55762s-.98926-.57128-1.70899-.57128zM16 8c0 4.41827-3.58179 8-8 8s-8-3.58173-8-8 3.58179-8 8-8 8 3.58173 8 8zm-1 0c0-3.86603-3.13403-7-7-7S1 4.13397 1 8c0 3.86597 3.13403 7 7 7s7-3.13403 7-7z" />
</svg>
    </HvIconBase>
)};

Severity5.propTypes = HvIconBase.propTypes;

export default Severity5;