const styles = {
  root: {
    display: "table-row",
    alignItems: "center",
    width: "100%",
  },
  headCell: {
    display: "table-cell",
  },
};

export default styles;
