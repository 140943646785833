
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const LocationPin = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="LocationPin" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.988 16l-.381-.434a42.84606 42.84606 0 01-2.782-3.53C2.951 9.384 2 7.354 2 6.002a6 6 0 0112 0c0 1.37-.989 3.443-2.939 6.16-1.062 1.475-2.632 3.32-2.7 3.4zm.013-15a5.006 5.006 0 00-5 5c0 .642.343 2.2 2.64 5.458.73 1.033 1.514 2.041 2.336 3.005.538-.645 1.543-1.873 2.272-2.887C12.644 8.244 13 6.653 13 6.002a5.006 5.006 0 00-5-5zM8 8.973a3 3 0 113-3 3 3 0 01-3 3zm0-5a2 2 0 102 2 2 2 0 00-2-2z" />
</svg>
    </HvIconBase>
)};

LocationPin.propTypes = HvIconBase.propTypes;

export default LocationPin;