
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Priority2 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Priority2" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M5.06152 4.41162H3.00586v7.13867h1.16699v-2.6709h.74219c.90137 0 1.59668-.19873 2.08496-.5957s.73242-.96826.73242-1.71387c0-.70947-.22168-1.24658-.66699-1.61133-.44336-.36474-1.11231-.54687-2.00391-.54687zm1.04981 3.17383c-.28027.2085-.71973.3125-1.31836.3125h-.62012V5.38818h.81055c.53027 0 .9209.10107 1.17188.30273s.37598.50781.37598.91797c-.00001.44288-.13966.76807-.41993.97657zm6.88281 2.9541v1.01074H8.15527v-.86914l1.84082-1.85059c.54395-.55664.9043-.95117 1.08105-1.18408.17773-.23291.30762-.45166.38867-.65674s.12207-.4248.12207-.65918c0-.32227-.09668-.57617-.29004-.76172-.19434-.18555-.46191-.27832-.80371-.27832-.27344 0-.53809.05029-.79395.15137-.25487.10108-.55077.28321-.88573.54688l-.62011-.75684c.39746-.33545.7832-.57275 1.15723-.71289s.77344-.20996 1.19629-.20996c.66406 0 1.19629.17334 1.59668.52002s.60059.81299.60059 1.39893c0 .32227-.05762.62842-.17383.91797-.11523.28955-.29297.58838-.53125.896-.24023.30762-.6377.72363-1.19434 1.24756l-1.24023 1.20117v.04883h3.38866zM8 0C3.58179 0 0 3.58173 0 8s3.58179 8 8 8 8-3.58173 8-8-3.58179-8-8-8zm0 15c-3.86597 0-7-3.13403-7-7 0-3.86603 3.13403-7 7-7s7 3.13397 7 7c0 3.86597-3.13403 7-7 7z" />
</svg>
    </HvIconBase>
)};

Priority2.propTypes = HvIconBase.propTypes;

export default Priority2;