
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Storage = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Storage" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M16 .470581H0V15.5294h16V.470581z" opacity=".01" />
  <path fill={colorArray[1]} className="color1"  d="M1 13.5341V2.46211C1 .889404 5.419.470581 8 .470581c2.581 0 6.917.415059 7 1.951059V13.5341c0 1.5765-4.4 1.9953-7 1.9953-2.6 0-7-.4235-7-1.9953zm.93298 0c.14.3765 2.37004 1.1087 6.06604 1.1087 3.69598 0 5.89998-.7162 6.06698-1.103V11.055c-1.9438.6554-4.0025.9566-6.066.8876-2.06398.0685-4.12297-.2333-6.06702-.8894v2.4809zm0-3.64893v.11106C2.07398 10.3614 4.3 11.0776 8 11.0776s5.9-.6983 6.066-1.07761V7.46823c-1.9438.65537-4.0025.95657-6.066.88753-2.06397.06851-4.12296-.23333-6.06702-.88942v2.41883zm0-3.58683v.11106C2.07398 6.77458 4.3 7.49082 8 7.49082s5.9-.6993 6.066-1.07859V6.2974h.008c0-.10353 0-.19576-.008-.47058V3.8814c-1.9438.65538-4.0025.95658-6.066.88753-2.06392.06893-4.12292-.23259-6.06702-.88847v2.41788zM8 3.90587c3.7 0 5.9-.69835 6.066-1.07764v-.00471c.0291-.05999.044-.12514.044-.19106 0-.06591-.0149-.13106-.044-.19106-.251-.37647-2.44-1.06917-6.066-1.06917-3.733 0-5.92604.73976-6.06604 1.1087-.02441.05303-.03699.11021-.03699.168 0 .0578.01258.11497.03699.168.06164.07846.13632.1471.22107.2033C4.01132 3.7135 6.00628 4.01611 8 3.90682v-.00095z" />
</svg>
    </HvIconBase>
)};

Storage.propTypes = HvIconBase.propTypes;

export default Storage;