
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Machine = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Machine" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13.394 15a1.482 1.482 0 00-.82544-1.92652q-.034-.01354-.0686-.02551V9.364a1.491 1.491 0 000-2.816V3.93952a2 2 0 10-2.429-2.42895H5.408A1.5 1.5 0 103.5 3.41852V5.011a4.037 4.037 0 00-.26 7.945v-2.344l.8-.808.8.808v2.344a4.035 4.035 0 00-.341-7.953V3.41852a1.5 1.5 0 00.908-.90795h4.663a2 2 0 001.43 1.429V6.548a1.491 1.491 0 000 2.816v3.684a1.482 1.482 0 00-.91949 1.88342q.01189.03461.02551.06861H8v1h8V15zM7.04 8.996V9a3.06515 3.06515 0 01-1.2 2.432v-1.23l-.289-.293-.8-.809-.711-.718-.711.718-.8.809-.289.292v1.227a3.06185 3.06185 0 01-1.2-2.432 3 3 0 016 0zM4 2.51057a.5.5 0 11.5-.5.5.5 0 01-.5.5zm7-.5a1 1 0 111 1 1 1 0 01-1-1zM12 7.456a.5.5 0 11-.5.5.5.5 0 01.5-.5zm0 7.5a.5.5 0 11.5-.5.5.5 0 01-.5.5z" />
</svg>
    </HvIconBase>
)};

Machine.propTypes = HvIconBase.propTypes;

export default Machine;