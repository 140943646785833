
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Service = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Service" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M8.00006 15.998L.00006104 11.0989V9.93612L8.00604 14.8352 15.999 9.93612v1.16378L8.01105 15.992l-.01099.006zm0-2.5065L.00006104 8.59238V7.4286L8.00604 12.3337 15.999 7.43459v1.16479L8.01105 13.4905l-.01099.001zm0-2.4256L.00006104 6.16684V4.96707L8.00006-.00100708 15.988 4.95706v1.21077L8.00006 11.0599v.006zm0-1.15977L15.074 5.57195 7.99896 1.17876.924011 5.57195 8.00006 9.90613z" />
</svg>
    </HvIconBase>
)};

Service.propTypes = HvIconBase.propTypes;

export default Service;