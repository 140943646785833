
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Priority5 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Priority5" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M5.10059 4.41162H3.04492v7.13867h1.16699v-2.6709h.74219c.90137 0 1.59668-.19873 2.08496-.5957s.73242-.96826.73242-1.71387c0-.70947-.22168-1.24658-.66699-1.61133-.44336-.36474-1.1123-.54687-2.0039-.54687zm1.0498 3.17383c-.28027.2085-.71973.3125-1.31836.3125h-.62012V5.38818h.81055c.53027 0 .9209.10107 1.17188.30273s.37598.50781.37598.91797c-.00001.44288-.13966.76807-.41993.97657zm6.80469 1.63574c0 .76172-.23828 1.35645-.71582 1.78467-.47656.42822-1.1543.64209-2.0332.64209-.79785 0-1.42383-.12842-1.87988-.38574v-1.04004c.26367.1499.56641.26514.9082.34668s.65918.12207.95215.12207c.51758 0 .91113-.11572 1.18164-.34668s.40527-.56982.40527-1.01562c0-.85303-.54395-1.2793-1.63086-1.2793-.15332 0-.3418.01562-.56641.04639s-.42187.06591-.59082.10497l-.5127-.30273.27344-3.48633h3.71094v1.02051h-2.7002L9.5957 7.19971c.11426-.01953.25293-.04248.41699-.06836.16504-.02588.36621-.03906.60352-.03906.71973 0 1.28906.19043 1.70898.57129s.62989.8999.62989 1.55761zM8 0C3.58179 0 0 3.58173 0 8s3.58179 8 8 8 8-3.58173 8-8-3.58179-8-8-8zm0 15c-3.86597 0-7-3.13403-7-7 0-3.86603 3.13403-7 7-7s7 3.13397 7 7c0 3.86597-3.13403 7-7 7z" />
</svg>
    </HvIconBase>
)};

Priority5.propTypes = HvIconBase.propTypes;

export default Priority5;