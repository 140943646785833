
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Strikethrough = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Strikethrough" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M14 8.49183h-3.72c.12.1.25.19.36.29a2.783 2.783 0 01.69 1.95 2.883 2.883 0 01-1.12 2.4 4.79994 4.79994 0 01-3.03.86 7.39984 7.39984 0 01-3.19-.53v-1.31a8.62418 8.62418 0 001.57.48 7.92659 7.92659 0 001.68.18 3.42994 3.42994 0 002.04-.52 1.7 1.7 0 00.69-1.43 1.819 1.819 0 00-.24-.99 2.15809 2.15809 0 00-.81-.72 11.84363 11.84363 0 00-1.51-.65H2v-1h3.4a4.78308 4.78308 0 01-.54-.47 3.07 3.07 0 01-.7-2.08 2.576 2.576 0 011.01-2.15 4.2 4.2 0 012.68-.8 7.98912 7.98912 0 013.2.64l-.42 1.18a7.17024 7.17024 0 00-2.81-.61 2.664 2.664 0 00-1.68.46 1.532 1.532 0 00-.61 1.29 1.915 1.915 0 00.22.99 1.96885 1.96885 0 00.75.71 9.3997 9.3997 0 001.62.72 3.40218 3.40218 0 01.34.12H14z" />
</svg>
    </HvIconBase>
)};

Strikethrough.propTypes = HvIconBase.propTypes;

export default Strikethrough;