
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleStandingUpperClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleStandingUpperClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M25 20.9808a13.18574 13.18574 0 00-.09-1.45823c-.014-.12793-.03-.25387-.047-.3808a13.09557 13.09557 0 00-.261-1.38027l-.014-.056a12.51965 12.51965 0 00-.418-1.29332c-.043-.11394-.087-.22588-.133-.33782a11.79231 11.79231 0 00-.563-1.19937l-.026-.047a11.09293 11.09293 0 00-.692-1.07543c-.066-.092-.134-.18191-.2-.27186a10.30565 10.30565 0 00-.818-.9515l-.029-.029a9.18392 9.18392 0 00-.919-.79958c-.083-.065-.167-.12693-.253-.1879a8.405 8.405 0 00-1.031-.64466 6.00149 6.00149 0 10-7 0 8.57961 8.57961 0 00-1.042.65065c-.08.058-.158.11594-.237.17591a9.74639 9.74639 0 00-1.782 1.79905c-.063.082-.124.16492-.185.24887a11.44122 11.44122 0 00-1.3 2.34377c-.042.1-.082.19989-.121.30884a12.68755 12.68755 0 00-.7 2.75954c-.017.11694-.031.23288-.044.34982A13.43757 13.43757 0 007 21.00579c0 .26486.013.52372.028.78259a1.95635 1.95635 0 00.008.21688H9v5.99685h3V32h8v-3.99789h3v-5.99685h1.963c.024-.32882.037-.66165.037-.99947zm-9-18.965a3.9979 3.9979 0 11-4 3.99789 3.99895 3.99895 0 014-3.9979zm2 23.98737v3.99789h-4v-3.9979h-3v-3.9979h10v3.9979z" />
</svg>
    </HvIconBase>
)};

FemaleStandingUpperClothes.propTypes = HvIconBase.propTypes;

export default FemaleStandingUpperClothes;