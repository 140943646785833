
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Priority4 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Priority4" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M4.93457 4.41162H2.87891v7.13867H4.0459v-2.6709h.74219c.90137 0 1.59668-.19873 2.08496-.5957s.73242-.96826.73242-1.71387c0-.70947-.22168-1.24658-.66699-1.61133-.44336-.36474-1.11231-.54687-2.00391-.54687zm1.04981 3.17383c-.28027.2085-.71973.3125-1.31836.3125H4.0459V5.38818h.81055c.53027 0 .9209.10107 1.17188.30273s.37597.50782.37597.91798c0 .44287-.13965.76806-.41992.97656zm6.1748 1.45508V4.39209h-1.11816L7.7793 9.10889v.88379h3.26172v1.55762h1.11816V9.99268h.96191v-.95215h-.96191zm-1.11816-1.79199v1.79199H8.84863l1.77246-2.57812c.19531-.30615.33887-.56641.42969-.78125h.03906c-.03222.40673-.04882.92919-.04882 1.56738zM8 0C3.58179 0 0 3.58173 0 8s3.58179 8 8 8 8-3.58173 8-8-3.58179-8-8-8zm0 15c-3.86597 0-7-3.13403-7-7 0-3.86603 3.13403-7 7-7s7 3.13397 7 7c0 3.86597-3.13403 7-7 7z" />
</svg>
    </HvIconBase>
)};

Priority4.propTypes = HvIconBase.propTypes;

export default Priority4;