
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Scale = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Scale" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13 2v-.5H9.929a1.99 1.99 0 00-3.858 0H3V2S0 6.344 0 8a3 3 0 006 0c0-1.314-1.885-4.314-2.665-5.5h2.736A2 2 0 007.5 3.93V15H5v1h6v-1H8.5V3.93A2 2 0 009.929 2.5h2.736C11.885 3.686 10 6.686 10 8a3 3 0 006 0c0-1.656-3-6-3-6zM3 10a2 2 0 01-1.929-1.5h3.858A2 2 0 013 10zm.636-5.108A13.6 13.6 0 014.9 7.5H1.1a13.556 13.556 0 011.26-2.62c.218-.374.44-.732.64-1.05.2.321.417.683.636 1.062zM8 3a1 1 0 111-1 1 1 0 01-1 1zm4.36 1.88c.223-.375.44-.733.64-1.051.2.321.422.683.641 1.062A13.518 13.518 0 0114.9 7.5h-3.8a13.556 13.556 0 011.26-2.619zM13 10a2 2 0 01-1.929-1.5h3.858A2 2 0 0113 10z" />
</svg>
    </HvIconBase>
)};

Scale.propTypes = HvIconBase.propTypes;

export default Scale;