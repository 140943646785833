
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Disk = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Disk" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8 0a8 8 0 108 8 8 8 0 00-8-8zm7 8a8.24376 8.24376 0 01-.7 3.1l-3.5-2a3.55358 3.55358 0 00.2-1 2.934 2.934 0 00-4-2.8L5 1.8a6.4278 6.4278 0 013.1-.7A6.788 6.788 0 0115 7.77414q.00188.11293 0 .22586zm-5 0a2 2 0 11-2-2 2 2 0 012 2zM4.1 2.2l2 3.5-.4.4-3.5-2a5.7523 5.7523 0 011.9-1.9zM1 8a8.24376 8.24376 0 01.7-3.1l3.5 2a3.55358 3.55358 0 00-.2 1 2.934 2.934 0 004 2.8l2 3.5a6.4278 6.4278 0 01-3.1.7A6.788 6.788 0 011 8.22586Q.99813 8.11293 1 8zm10.9 5.8l-2-3.5.4-.4 3.5 2a5.7523 5.7523 0 01-1.9 1.9z" />
</svg>
    </HvIconBase>
)};

Disk.propTypes = HvIconBase.propTypes;

export default Disk;