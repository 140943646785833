
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Cash = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Cash" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.664 8.582l-.363-.139V6.7a5.15006 5.15006 0 011.492.354l.349-.875a5.269 5.269 0 00-1.841-.392V5h-.636v.8a2.549 2.549 0 00-1.389.52 1.346 1.346 0 00-.511 1.082 1.528 1.528 0 00.36 1.035 2.83407 2.83407 0 001.145.72l.4.148V11.1a4.312 4.312 0 01-.985-.141 4.80742 4.80742 0 01-.961-.337v1a4.94488 4.94488 0 001.941.368V13H7.3v-1.037a2.582 2.582 0 001.463-.555 1.462 1.462 0 00.511-1.143 1.363 1.363 0 00-.354-.947 3.325 3.325 0 00-1.256-.736zm-.994-.373a1.73683 1.73683 0 01-.614-.359.64.64 0 01-.17-.454.56.56 0 01.2-.439 1.121 1.121 0 01.583-.234zm.635 2.849V9.533a2.01 2.01 0 01.648.347.6.6 0 01.194.457q.001.592-.842.721zM16 1v12h-1V2H2V1h14zM0 15h14V3H0zM1 4h12v10H1z" />
</svg>
    </HvIconBase>
)};

Cash.propTypes = HvIconBase.propTypes;

export default Cash;