const styles = (theme) => ({
  root: {
    display: "block",
    background: theme.hv.palette.atmosphere.atmo1,

    overflowY: "auto",
  },
  list: {},
  listItem: {},
});

export default styles;
