
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Magnet = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Magnet" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13.19413 5.693l-5.693 5.693a2.01242 2.01242 0 01-2.846-2.846l5.693-5.693L7.50113 0l-5.689 5.693a6.039 6.039 0 008.45374 8.62627q.04356-.0427.08626-.08627l5.692-5.693zm1.418 2.847l-1.423 1.423-1.418-1.424 1.423-1.423zm-7.111-7.116l1.423 1.423-1.423 1.423-1.423-1.423zm-4.981 12.1a5.038 5.038 0 010-7.116l2.847-2.85 1.423 1.428-2.846 2.842a3.019 3.019 0 004.17508 4.3619q.04747-.04543.09292-.0929l2.847-2.846 1.423 1.423-2.847 2.846a5.036 5.036 0 01-7.115 0z" />
</svg>
    </HvIconBase>
)};

Magnet.propTypes = HvIconBase.propTypes;

export default Magnet;