
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Move = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Move" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M15.99847 7.99988L16 8.00159l-3.18073 3.18066-.70709-.70715L14.08722 8.5H8.5v5.58716l1.9751-1.975.70715.70716L8.00159 16l-.00171-.00146L7.99841 16l-3.18066-3.18066.707-.70716L7.5 14.0874V8.5H1.91278l1.975 1.9751-.70709.70715L0 8.00159l.00153-.00171L0 7.99841l3.18073-3.18066.70709.707L1.91254 7.5H7.5V1.9126L5.52478 3.88782l-.707-.70716L7.99841 0l.00147.00146L8.00159 0l3.18066 3.18066-.70715.70716L8.5 1.91284V7.5h5.58746l-1.97528-1.97522.70709-.707L16 7.99841z" />
</svg>
    </HvIconBase>
)};

Move.propTypes = HvIconBase.propTypes;

export default Move;