
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleWalkingHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleWalkingHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.67 22.00108h1.3c.019-.33.029-.66393.029-.99989 0-4.33253-1.6-8.07013-3.915-9.86293A5.99922 5.99922 0 0022 6.00281h2V4.003h-2.344a6.00021 6.00021 0 00-11.315 0H8v1.99981h2a5.99922 5.99922 0 002.916 5.13545C10.6 12.93106 9 16.66866 9 21.00119c0 .337.01.67093.028.99989h1.305L7 32h6l3-8.999L19 32h6zM16 2.00325A4.00007 4.00007 0 0119.463 4.003h-6.927A4.00005 4.00005 0 0116 2.00325zm-4 3.99956h8a4 4 0 01-8 0zm2.9 13.99849l-.455 1.36785-2.879 8.63107H9.782l2.456-7.36622.877-2.63271h-2.074a12.34477 12.34477 0 011.7-5.69939c1.27-1.99978 2.588-2.29975 3.274-2.29975s1.991.3 3.261 2.29975a12.32568 12.32568 0 011.7 5.69939zm2.876 1.99978h1.784l2.666 7.99914h-1.785z" />
</svg>
    </HvIconBase>
)};

MaleWalkingHat.propTypes = HvIconBase.propTypes;

export default MaleWalkingHat;