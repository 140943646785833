
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Dozer = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Dozer" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 14l-4.00013-5v2.366l-1.039-.26a2.5 2.5 0 00-1.461-1.9V6.793L4.70664 2H1.99955v6.184a2.993 2.993 0 001 5.816h5.50021a2.50008 2.50008 0 002.41108-1.874l1.089.274V14zm-3.0001-2v-.149l.919 1.149h-.919zM2.99958 3h1.29305l4.20713 4.207V9L2.99958 8zm5.50018 10H2.99958a2.0004 2.0004 0 11-.08-4l5.40017.982.09.018h.09a1.5 1.5 0 110 3zM2.5 10.5h1v1h-1zM8 11h1v1H8z" />
</svg>
    </HvIconBase>
)};

Dozer.propTypes = HvIconBase.propTypes;

export default Dozer;