
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleWalkingUpperClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleWalkingUpperClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.993 6.00585H24V4.0063h-2.339a5.92391 5.92391 0 00-.519-1.09975 6.00045 6.00045 0 00-10.8 1.09975H8v1.99955h2a5.97939 5.97939 0 002.9 5.13085c-.018.014-.035.034-.053.048a7.79881 7.79881 0 00-1.4 1.46367c-.022.029-.042.06-.063.09q-.277.3829-.529.81082c-.035.061-.071.122-.106.184-.167.29993-.325.60586-.47.92979-.014.029-.028.057-.041.086q-.24.54389-.438 1.13174c-.018.054-.033.111-.05.165q-.158.48989-.284 1.00677-.039.159-.075.32093c-.073.32692-.135.65985-.188.99977-.015.091-.032.18-.045.27294-.06.42891-.1.86681-.132 1.31271v.144c-.016.29993-.029.59987-.029.8998 0 .26894.01.53288.022.79982L9 28.0009h1.332L9 32h6l1-2.99932L17 32h6l-1.333-3.9991H23l-.023-6.2326c.012-.25394.022-.50788.022-.76583 0-.29093-.013-.57786-.028-.8638v-.136c0-.019-.005-.038-.006-.057-.026-.43491-.07-.86081-.128-1.27972-.016-.116-.037-.22795-.056-.34292-.049-.31093-.106-.61686-.173-.91779-.028-.128-.057-.25494-.088-.38092q-.117-.4709-.26-.91879c-.025-.08-.047-.163-.074-.24095q-.192-.56987-.424-1.09975c-.022-.051-.048-.1-.071-.149a9.43114 9.43114 0 00-.437-.8638c-.042-.075-.085-.148-.128-.221q-.237-.39991-.5-.76583c-.03-.041-.058-.086-.088-.126a7.766 7.766 0 00-1.387-1.44768c-.021-.016-.04-.039-.061-.055a5.98537 5.98537 0 002.903-5.13067zM16 2.00676a3.98215 3.98215 0 013.445 1.99954h-6.89A3.98215 3.98215 0 0116 2.00676zm2.442 27.99369l-.667-1.99955h1.784l.666 1.99955zM21 22.00225v3.9991h-6.108l-.455 1.36665-.878 2.63241h-1.784l.456-1.36669.878-2.63241H11l.017-3.9991zm-5-11.9973a3.99954 3.99954 0 01-4-3.9991h8a3.99954 3.99954 0 01-4 3.9991z" />
</svg>
    </HvIconBase>
)};

FemaleWalkingUpperClothesHat.propTypes = HvIconBase.propTypes;

export default FemaleWalkingUpperClothesHat;