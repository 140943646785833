
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Truck = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Truck" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 6.8L11.206 2H9v9.5h-.5V2H0v10h.006a2 2 0 102-2A1.964 1.964 0 001 10.278V3h6.5v7.676a2 2 0 10.435 1.824h2.142a1.99 1.99 0 003.856.006H16zM2.006 11a1 1 0 11-1 1 1 1 0 011-1zm4 2a1 1 0 111-1 1 1 0 01-1 1zm6 0a1 1 0 111-1 1 1 0 01-1 1zM15 11.5h-1.063a1.99 1.99 0 00-3.86-.006H10V3h.793L15 7.21z" />
</svg>
    </HvIconBase>
)};

Truck.propTypes = HvIconBase.propTypes;

export default Truck;