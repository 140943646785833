
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const ArchiveShared = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="ArchiveShared" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 3H8L6 1H0v14h2.6a5.49965 5.49965 0 00-.1 1h11a5.49433 5.49433 0 00-.1-1H16zM3.621 15a4.489 4.489 0 018.758 0zM8 10a2 2 0 112-2 2.00006 2.00006 0 01-2 2zm7 4h-1.882a5.51571 5.51571 0 00-3.793-3.322 3 3 0 10-2.65 0A5.51416 5.51416 0 002.882 14H1V2h4.586l1.707 1.707.293.293H15z" />
</svg>
    </HvIconBase>
)};

ArchiveShared.propTypes = HvIconBase.propTypes;

export default ArchiveShared;