
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Operation = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Operation" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 5.5v-1h-1.29315A3.70514 3.70514 0 0013.97 2.73706l.92-.92017-.707-.70689-.92.92A3.71089 3.71089 0 0011.5 1.3V0h-1v1.30005a3.69892 3.69892 0 00-1.763.7279l-.919-.91906-.707.707.919.91911A3.71476 3.71476 0 007.3 4.5H6v1h1.3a3.71424 3.71424 0 00.729 1.76392l-.918.918.707.707.918-.918A3.69978 3.69978 0 0010.5 8.7V10h1V8.7a3.71168 3.71168 0 001.763-.729l.92.91894.707-.70694-.92-.91906A3.7051 3.7051 0 0014.707 5.5zm-5 2.25A2.75 2.75 0 1113.75 5 2.75 2.75 0 0111 7.75zm-3.576 2.394l-.974.393a2.95094 2.95094 0 00-.936-.954l.411-.96509-.92-.39087-.411.96696a2.98007 2.98007 0 00-1.335-.012l-.393-.974-.928.374.393.975a2.97924 2.97924 0 00-.951.93591L.409 10.078l-.39.91907.966.41089a2.95884 2.95884 0 00-.012 1.337L0 13.13794l.375.928.973-.39392a2.95526 2.95526 0 00.936.952l-.41095.967.921.39087.411-.968a2.70129 2.70129 0 001.336.01416L4.934 16l.927-.375-.393-.973a2.9466 2.9466 0 00.952-.93592l.966.40992.391-.91993-.966-.41a2.95523 2.95523 0 00.014-1.332l.973-.392zM5.74 12.8879a2 2 0 11.014-1.532 2 2 0 01-.01395 1.53204z" />
</svg>
    </HvIconBase>
)};

Operation.propTypes = HvIconBase.propTypes;

export default Operation;