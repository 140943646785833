
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleRunningUpperClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleRunningUpperClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M23 21.13609v-.18293c0-.46083-.025-.91266-.062-1.35849-.011-.134-.025-.2669-.039-.39985q-.06-.57578-.158-1.13057c-.013-.076-.023-.15394-.037-.22992-.086-.44583-.19-.87667-.31-1.29951-.021-.076-.046-.14994-.069-.22591q-.168-.55979-.375-1.08359c-.021-.052-.039-.1-.06-.15594a11.26843 11.26843 0 00-1.194-2.20717l-.038-.054a7.80324 7.80324 0 00-1.561-1.65638l-.009-.009a6.001 6.001 0 10-6.17 0l-.006.006a7.84644 7.84644 0 00-1.572 1.66937l-.021.03a11.23905 11.23905 0 00-1.209 2.23616c-.016.037-.029.075-.044.112-.146.36686-.277.74872-.4 1.14257-.018.061-.038.119-.055.17993-.123.42884-.23.87067-.317 1.3275-.011.056-.018.114-.028.17093-.07.39086-.125.79071-.168 1.19955-.012.118-.024.23591-.034.35587-.039.46982-.066.94564-.066 1.43146 0 .15894.009.31288.013.46982 0 .17694 0 .35487.015.5298L4 22.04175v5.95976l10.08827-.0353 1.95814-5.22174 1.11778 3.35208L15.0971 32H21l1.966-6.0977-1.3-3.89854h1.305c.016-.28489.022-.57576.029-.86767zM12 6.00979a4 4 0 114 3.99849 3.99925 3.99925 0 01-4-3.99849zm.5992 19.99247L6 26.02245V24.003l5-.02726-.01-1.972h3.10357zm8.2638-.093l-1.32 4.09145h-1.724l1.117-3.1918.226-.64475-.216-.64776-1.171-3.51268h1.784z" />
</svg>
    </HvIconBase>
)};

MaleRunningUpperClothes.propTypes = HvIconBase.propTypes;

export default MaleRunningUpperClothes;