const styles = {
  root: {
    position: "absolute",
    width: "100%",
    zIndex: 1,
    top: "0px",
    left: "0px",
  },
};

export default styles;
