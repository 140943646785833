
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const ShowerNight = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="ShowerNight" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M6.788 13.86l.97.243.242-.976-.97-.244-.242.977zM6.3 15.804l.982.196.233-.921-.97-.244-.245.969zm-1.248-3.898l.97.244.242-.976-.97-.244-.242.976zm-.607 2.442l.971.244.242-.977-.971-.244-.242.977zm-2.363 1.453l.484.123.5.071.226-.916-.97-.244-.24.966zm.968-3.895l.97.244.243-.976-.971-.244-.242.976zm5.617 2.442l.97.244.242-.977-.97-.244-.242.977z" />
  <path fill={colorArray[0]} className="color0"  d="M13.615 9.312A5.012 5.012 0 0016 5.493a3.986 3.986 0 01-5.456-1.507 4.05 4.05 0 010-3.986 5.02 5.02 0 00-4.521 5.011H6a4.008 4.008 0 00-3.908 3.161A3.019 3.019 0 00.14 11.956a2.973 2.973 0 002.392 2.056l.039-.151.964.242.242-.976-.97-.244-.036.146a2 2 0 01-.377-3.9l.551-.176.124-.568A3.022 3.022 0 016 6.018c.044 0 .085.014.128.016A3.059 3.059 0 018.5 7.369l.5.757.8-.426a1.49 1.49 0 012.019.627 1.513 1.513 0 01.168.577c0 .044.014.087.014.132v.024l-.025.847.089.018.736.15a1.511 1.511 0 01-.3 2.99h-.264l-.02.077-.958-.241-.242.977.97.243.013-.049h.5a2.514 2.514 0 001.115-4.76zm-.671-.785a2.5 2.5 0 00-2.444-2 2.475 2.475 0 00-1.167.29 4 4 0 00-2.3-1.662c0-.047-.014-.091-.014-.139a4.03 4.03 0 012.023-3.5c-.015.159-.023.32-.023.481a5.022 5.022 0 005 5.034c.161 0 .321-.008.479-.023a3.834 3.834 0 01-1.554 1.519z" />
  <path fill={colorArray[0]} className="color0"  d="M12.696 11.174l-.97-.244-.242.976.97.244.242-.976zm-3.423.732l.97.244.242-.976-.97-.244-.242.976zm1.242 3.898l.981.196.23-.919-.971-.244-.24.967zm-3.242-3.898l.97.244.242-.976-.97-.244-.242.976z" />
</svg>
    </HvIconBase>
)};

ShowerNight.propTypes = HvIconBase.propTypes;

export default ShowerNight;