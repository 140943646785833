
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const User = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="User" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M9.6 8.7A4.49656 4.49656 0 008.03809 0Q8.019-.00007 8 0a4.481 4.481 0 00-4.5 4.46194q-.00009.019 0 .03808A4.563 4.563 0 006.4 8.7 7.687 7.687 0 00.5 16h1a6.487 6.487 0 016.474-6.5H8a6.487 6.487 0 016.5 6.474V16h1a7.493 7.493 0 00-5.9-7.3zM4.5 4.5A3.543 3.543 0 018 1a3.543 3.543 0 013.5 3.5A3.543 3.543 0 018 8a3.543 3.543 0 01-3.5-3.5z" />
</svg>
    </HvIconBase>
)};

User.propTypes = HvIconBase.propTypes;

export default User;