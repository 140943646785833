
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const HardDisk = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="HardDisk" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M13 14H9v-2H8v2H4v-2H3v2H2v-4H1V6h1V5H1V4h1V3H0V2h16v10h-2v2h-1zm-3-1h3v-1h-3v1zm-5 0h2v-1H5v1zm-1.938-2h12V3h-12v8zM7 7c0-.59334.17595-1.17336.50559-1.66671.32965-.49335.79818-.87787 1.34636-1.10493.54818-.22706 1.15135-.28647 1.73335-.17072.5819.11576 1.1165.40148 1.536.82104.4196.41956.7053.95411.8211 1.53605.1157.58194.0563 1.18514-.1708 1.73332-.227.54818-.6115 1.01671-1.1049 1.34636C11.1734 9.82405 10.5933 10 10 10c-.79565 0-1.55871-.31607-2.12132-.87868C7.31607 8.55871 7 7.79565 7 7zm1 0c0 .39556.1173.78224.33706 1.11114.21976.3289.53212.58524.89757.73662.36546.15137.76757.19098 1.15557.11381.3879-.07717.7443-.26765 1.024-.54736.2797-.2797.4702-.63607.5474-1.02403.0771-.38796.0375-.79009-.1138-1.15555-.1514-.36545-.4078-.67781-.7367-.89757C10.7822 5.1173 10.3956 5 10 5c-.53043 0-1.03914.21071-1.41421.58579C8.21071 5.96086 8 6.46957 8 7zm1 0c0-.19778.05865-.39112.16853-.55557.10988-.16445.26606-.29262.44879-.36831.18272-.07569.38378-.09549.57778-.05691.194.03859.3722.13383.512.27368.1399.13986.2351.31804.2737.51202.0386.19398.0188.39505-.0569.57777-.0757.18273-.2039.33891-.3683.44879C10.3911 7.94135 10.1978 8 10 8c-.26522 0-.51957-.10536-.70711-.29289C9.10536 7.51957 9 7.26522 9 7z" />
</svg>
    </HvIconBase>
)};

HardDisk.propTypes = HvIconBase.propTypes;

export default HardDisk;