
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const TemplateAlt = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="TemplateAlt" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M3.653 4.2v2.051a1.143 1.143 0 01-.39.954A2.1 2.1 0 012 7.528v.946a2.117 2.117 0 011.23.3 1.135 1.135 0 01.423 1v2.034a1.95 1.95 0 00.7 1.6A3.094 3.094 0 006.328 14v-.94a1.9 1.9 0 01-1.152-.323 1.186 1.186 0 01-.393-.993V9.717a1.591 1.591 0 00-1.5-1.674V7.96h.032a1.59 1.59 0 001.468-1.7V4.274q0-1.3 1.548-1.336V2a3.044 3.044 0 00-1.948.578 1.953 1.953 0 00-.73 1.622zm9.084 3.005a1.143 1.143 0 01-.39-.954V4.2a1.953 1.953 0 00-.726-1.618A3.045 3.045 0 009.672 2v.934q1.545.039 1.545 1.336v1.981a1.59 1.59 0 001.468 1.7h.032v.081a1.591 1.591 0 00-1.5 1.674v2.024a1.186 1.186 0 01-.393.993 1.9 1.9 0 01-1.152.323v.94a3.094 3.094 0 001.975-.585 1.95 1.95 0 00.7-1.6V9.773a1.132 1.132 0 01.423-1 2.117 2.117 0 011.23-.3v-.945a2.1 2.1 0 01-1.263-.323z" />
</svg>
    </HvIconBase>
)};

TemplateAlt.propTypes = HvIconBase.propTypes;

export default TemplateAlt;