
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Dragline = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Dragline" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M2.5 10.5h1v1h-1zM8 11h1v1H8zm.49976-8.38195v3.15809L4.70664 1.97681H1.99955v6.196a2.99945 2.99945 0 001 5.82723h5.50021a2.5056 2.5056 0 001-4.79725V7.48744h3.85912L15 2.569v6.31212L10.99984 14H15v-3.17513l1-1.67222V0zm-5.50018.36069h1.29305l4.20713 4.21413v1.79746L2.99958 7.9884zm7.00023 8.51642a1.50148 1.50148 0 01-1.5 1.5029H2.99958a2.00427 2.00427 0 01-.08-4.00773l5.40017.9839.09.018h.09a1.50148 1.50148 0 011.50006 1.50293zm-.5-5.00965V4.19308l2.29108 2.29243zm.422-3.28634l1.12-.37472.782 2.27739zm3.07109 2.22529l-.656-1.90968 1.065.6773zm.735-2.21126l-1.32805-.84263 1.80706-.60116zm.272 8.78293v1.00193h-.919l.919-1.15122z" />
</svg>
    </HvIconBase>
)};

Dragline.propTypes = HvIconBase.propTypes;

export default Dragline;