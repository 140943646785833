
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const SwipeRight = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="SwipeRight" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M14.047 10.952v-3.5a1.5 1.5 0 00-1.5-1.5 1.471 1.471 0 00-1 .392 1.477 1.477 0 00-2 0 1.455 1.455 0 00-1.5-.3V2.152a1.53 1.53 0 00-1.8-1.171 1.5 1.5 0 00-1.2 1.471V9.17l-1.621-.928c-.02-.014-.04-.027-.061-.039l-.133-.072-.044-.021c-.051-.025-.114-.055-.14-.065a1.5 1.5 0 00-1.938.86 1.477 1.477 0 00.791 1.9l2.289 1.327a5 5 0 009.857-1.18zm-5.01 3.99a4 4 0 01-3.919-3.25.49.49 0 00-.235-.311l-.709-.408-1.773-1.021-.068-.039a.5.5 0 01.18-.968.484.484 0 01.168.03s.068.035.1.049l2.528 1.428a.5.5 0 00.6785-.19909l.0005-.00091a.50808.50808 0 00.059-.242V2.452a.5.5 0 111 0v6.5h1v-1.5a.5.5 0 011 0v1.5h1v-1.5a.5.5 0 011 0v1.5h1v-1.5a.5.5 0 011 0v3.5a4 4 0 01-4.008 3.99zm6.965-12.3314l-2.122 2.118-.707-.707.914-.913H10v-1h4.084l-.913-.913.707-.707L16 2.6076z" />
</svg>
    </HvIconBase>
)};

SwipeRight.propTypes = HvIconBase.propTypes;

export default SwipeRight;