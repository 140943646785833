
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Node = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Node" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M0 15V1h16v14H0zm1-1h14V2H1v12zm7-3.8291l-2.78101 1.3-3.23498-1.51582V5.93799l3.23498-1.40698.17701.07788 2.59997 1.12817L10.767 4.53101l.177.07788 3.066 1.3291v4.01806l-3.244 1.51585L8 10.1709zm2.324.0791V7.93994l-1.86099-.88696v2.32397L10.324 10.25zm-7.43496-.88306L4.771 10.25V7.93994l-1.88196-.8999v2.3269zM11.228 7.94897V10.249l1.883-.87888V7.11206l-1.883.83691zm-5.55295 0V10.249l1.86194-.87009V7.12012l-1.86194.82885zm3.328-1.6499L10.781 7.146l1.8311-.8169-1.8421-.80005-1.76695.77002zm-5.5531 0l1.77808.84693 1.83203-.8169-1.84204-.80005-1.76807.77002z" />
</svg>
    </HvIconBase>
)};

Node.propTypes = HvIconBase.propTypes;

export default Node;