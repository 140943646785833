
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Teapot = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Teapot" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M9 0a5.99978 5.99978 0 00-6 5.99964v.99994H0l3.06226 3.86786A6.00042 6.00042 0 0015 10V5.99964A5.99978 5.99978 0 009 0zM3 9.17774l-.93335-1.17822H3zm11 .82166a5 5 0 01-10 0V7.99952h10zM4.73431 6.99958L5.40985 6h7.1803l.67554.99957zM14 6.27228v.13483L13 5H9.5V4h-1v1H5L4 6.27259v-.27295a5 5 0 0110 0z" />
</svg>
    </HvIconBase>
)};

Teapot.propTypes = HvIconBase.propTypes;

export default Teapot;