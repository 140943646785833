
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Moon = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Moon" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8.123 16A8.1 8.1 0 016.284 0 7.1 7.1 0 0016 9.872a8.137 8.137 0 01-2.842 4.384A8.035 8.035 0 018.123 16z" />
  <path fill={colorArray[1]} className="color1"  d="M8.123 14.987a7.041 7.041 0 004.4-1.526 7.119 7.119 0 001.567-1.722 8.108 8.108 0 01-9.714-9.87 7.091 7.091 0 003.747 13.118m0 1.013A8.1 8.1 0 016.284 0 7.1 7.1 0 0016 9.871a8.137 8.137 0 01-2.842 4.384A8.04 8.04 0 018.123 16z" />
</svg>
    </HvIconBase>
)};

Moon.propTypes = HvIconBase.propTypes;

export default Moon;