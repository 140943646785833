
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Namespace = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Namespace" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M15 15v-2h1v2h-1zm-3 0v-1h2v1h-2zm-4 0v-1h2v1H8zm-4 0v-1h2v1H4zm-4 0v-1h2v1H0zm0-2v-2h1v2H0zm8.66504-2.244V9.673c.60375.29811 1.2649.4619 1.93796.48.772 0 1.1577-.23333 1.157-.7.005-.13619-.0407-.2694-.128-.37399-.1212-.12707-.2636-.23198-.4209-.31-.2646-.13787-.5374-.25911-.8171-.363-.5105-.16244-.98062-.43176-1.37899-.79001-.24731-.28228-.37624-.64904-.35998-1.02399-.00836-.23198.04338-.46218.15027-.66824.10688-.20606.26526-.381.45971-.50778.49924-.30359 1.07849-.44928 1.66199-.418.679-.00327 1.3507.14036 1.969.42102l-.405.944c-.5059-.23196-1.0512-.36568-1.607-.39402-.6613 0-.99234.18834-.99301.56501.00118.0936.0255.18547.07068.26745.04513.08199.10983.15156.18833.20255.3558.21694.7345.39403 1.1291.52801.3727.13132.7289.30511 1.0618.51798.2046.13825.3728.32386.4902.54101.112.22771.1669.4793.1599.73301.0133.25595-.0373.51119-.1472.74269-.11.2315-.276.432-.4828.5833-.5364.3397-1.1663.5014-1.8.462-.65213.0288-1.30221-.0927-1.89999-.355l.00403-.001zm-1.73206.251V7.379c.02743-.36142-.06963-.72134-.27502-1.01999-.11015-.11874-.24597-.21075-.3971-.26898-.15112-.05824-.31351-.08114-.47485-.06702-.2173-.01862-.43595.01423-.63818.09589-.20224.08165-.38243.20983-.52588.37411-.28704.47177-.41588 1.02286-.36792 1.573V11.006H3V5.10599h.98206l.1759.77401h.06506c.18372-.28632.44715-.51262.75794-.651.34124-.15593.71286-.23415 1.08801-.229.29108-.03862.58719-.00903.86487.08643.27768.09545.5293.25413.73511.46356.20604.20961.36057.46431.45117.74392.0906.27962.11488.57648.07092.8671V11.008l-1.25806-.001zM15 11V9h1v2h-1zM0 9V7h1v2H0zm15-2V5h1v2h-1zM0 5V3h1v2H0zm15-2V1h1v2h-1zm-3-1V1h2v1h-2zM8 2V1h2v1H8zM4 2V1h2v1H4zM0 2V1h2v1H0z" />
</svg>
    </HvIconBase>
)};

Namespace.propTypes = HvIconBase.propTypes;

export default Namespace;