
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Binoculars = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Binoculars" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M15.85815 10.56665h.01368l-1.91626-7.00757A2.49715 2.49715 0 009 4v3H7V4a2.49715 2.49715 0 00-4.95557-.44092L.12817 10.56665h.01368A3.46431 3.46431 0 000 11.5a3.5 3.5 0 007 0V10h2v1.5a3.5 3.5 0 007 0 3.46431 3.46431 0 00-.14185-.93335zM3.5 14A2.5 2.5 0 116 11.5 2.50008 2.50008 0 013.5 14zM6 5.98877v3.06689a3.46 3.46 0 00-4.303-.5415l.83-2.99516.50281-1.81445.0119-.043A1.49832 1.49832 0 016 4zM9 9H7V8h2zm1-3.01123V4a1.49832 1.49832 0 012.95831-.33838l.0119.043L13.473 5.519l.83 2.99512A3.46 3.46 0 0010 9.05566zM12.5 14a2.5 2.5 0 112.5-2.5 2.5 2.5 0 01-2.5 2.5z" />
</svg>
    </HvIconBase>
)};

Binoculars.propTypes = HvIconBase.propTypes;

export default Binoculars;