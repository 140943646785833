
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const GiftVoucher = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="GiftVoucher" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M0 2v12h16V2zm1 1h4.5v2.066a1.982 1.982 0 00-2.42341 1.40844L3.07 6.5H1zm4.5 10H1V7.5h2.07a2.01 2.01 0 00.985 1.26L2.6 10.7l.8.6 1.738-2.318a2.04876 2.04876 0 00.362-.048zM6 7.577l-.134.078-.366.212A.97893.97893 0 015 8a.92389.92389 0 01-.326-.065 1.017 1.017 0 01-.521-.435.889.889 0 010-1 .978.978 0 011.33116-.37548L5.499 6.133l.5.289.5-.289A.973.973 0 017 6a.985.985 0 01.847.5.889.889 0 010 1 1.02 1.02 0 01-.52.435A.929.929 0 017 8a.97507.97507 0 01-.5-.133l-.366-.213zM15 13H6.5V8.934a2.04876 2.04876 0 00.362.048L8.6 11.3l.8-.6-1.455-1.94a2.012 2.012 0 00.984-1.26H15zm0-6.5H8.929a1.981 1.981 0 00-2.40268-1.44078L6.5 5.066V3H15z" />
</svg>
    </HvIconBase>
)};

GiftVoucher.propTypes = HvIconBase.propTypes;

export default GiftVoucher;