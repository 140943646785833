
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleWalkingLowerClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleWalkingLowerClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M21.994 6.00973H24V4.01048h-2.338a6.0007 6.0007 0 00-11.321 0H8v1.99925h2.006a5.97831 5.97831 0 002.9 5.13108c-2.131 1.65438-3.653 4.96514-3.877 8.86368H9v7.997h1.332L9 32h6l1-2.99888L17 32h6l-1.333-3.9985H23v-7.997h-.029c-.223-3.89854-1.746-7.2093-3.88-8.86368a5.98732 5.98732 0 002.903-5.13109zM16 2.01123a3.98226 3.98226 0 013.445 1.99925h-6.89A3.98226 3.98226 0 0116 2.01123zm4.962 17.99326h-9.925a12.01229 12.01229 0 011.872-5.96177A4.17708 4.17708 0 0116 12.00748a4.16408 4.16408 0 013.089 2.03524 12.01328 12.01328 0 011.873 5.96177zM16 10.00823a3.99925 3.99925 0 01-4-3.9985h8a3.99925 3.99925 0 01-4 3.9985z" />
</svg>
    </HvIconBase>
)};

FemaleWalkingLowerClothesHat.propTypes = HvIconBase.propTypes;

export default FemaleWalkingLowerClothesHat;