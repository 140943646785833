
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Gyroscope = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Gyroscope" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M13.991 2.716l1.363-1.362-.708-.707-1.362 1.362A7.987 7.987 0 002.009 13.284L.646 14.647l.708.707 1.362-1.363A7.987 7.987 0 0013.991 2.716zM8 15a7 7 0 117-7 7 7 0 01-7 7zm2.7-9.7C10.22 3.313 9.218 2 8 2S5.78 3.313 5.3 5.3C3.313 5.78 2 6.782 2 8s1.313 2.22 3.3 2.7C5.777 12.69 6.779 14 8 14s2.22-1.312 2.7-3.3C12.689 10.222 14 9.22 14 8s-1.313-2.22-3.3-2.7zM5.1 9.6C3.813 9.2 3 8.581 3 8s.814-1.2 2.1-1.6A12.66811 12.66811 0 005 8a12.51084 12.51084 0 00.1 1.6zM8 3c.581 0 1.2.814 1.595 2.1A12.66785 12.66785 0 008 5a12.48012 12.48012 0 00-1.6.1C6.8 3.814 7.419 3 8 3zm0 10c-.581 0-1.2-.813-1.6-2.1a12.52171 12.52171 0 003.19 0C9.2 12.187 8.581 13 8 13zm1.841-3.159a10.73812 10.73812 0 01-3.682 0 10.73812 10.73812 0 010-3.682 10.73812 10.73812 0 013.682 0 10.73812 10.73812 0 010 3.682zM10.9 9.6A12.509 12.509 0 0011 8a12.67931 12.67931 0 00-.1-1.6C12.186 6.8 13 7.419 13 8s-.814 1.2-2.1 1.6z" />
</svg>
    </HvIconBase>
)};

Gyroscope.propTypes = HvIconBase.propTypes;

export default Gyroscope;