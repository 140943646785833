
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Priority1 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Priority1" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <g fill={colorArray[0]} className="color0" >
    <path d="M8 0C3.58179 0 0 3.58173 0 8s3.58179 8 8 8 8-3.58173 8-8-3.58179-8-8-8zm0 15c-3.86597 0-7-3.13403-7-7 0-3.86603 3.13403-7 7-7s7 3.13397 7 7c0 3.86597-3.13403 7-7 7z" />
    <path d="M5.79639 4.41162H3.74072v7.13867h1.16699v-2.6709h.74219c.90137 0 1.59668-.19873 2.08496-.5957s.73242-.96826.73242-1.71387c0-.70947-.22168-1.24658-.66699-1.61133-.44336-.36474-1.1123-.54687-2.0039-.54687zm1.0498 3.17383c-.28027.2085-.71973.3125-1.31836.3125h-.62012V5.38818h.81055c.53027 0 .9209.10107 1.17188.30273s.37598.50781.37598.91797c-.00001.44288-.13966.76807-.41993.97657zm2.35645-1.52344l.57617.72754c.62207-.50439.9873-.8042 1.0957-.89844.10938-.09424.20117-.18066.27637-.25879-.02637.32227-.03906.7583-.03906 1.30859v4.60938h1.14746V4.41162h-.95703L9.20264 6.06201z" />
  </g>
</svg>
    </HvIconBase>
)};

Priority1.propTypes = HvIconBase.propTypes;

export default Priority1;