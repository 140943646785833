
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 20, height: 20 };
    default:
    case "S":
      return { width: 24, height: 24 };
    case "M":
      return { width: 40, height: 40 };
    case "L":
      return { width: 104, height: 104 };
  }
};

const Running = ({
  color,
  iconSize = "S",
  viewbox = "0 0 24 24",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1,theme.palette.atmo2];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Running" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <circle cx={12} cy={12} r={12} fill={colorArray[0]} className="color0"  />
  <path fill={colorArray[1]} className="color1"  d="M17.99995 12.01245c.01379-3.3068-2.66131-5.99865-5.97499-6.0124-3.31368-.01375-6.01112 2.65579-6.02491 5.96259-.00789 1.89335.88206 3.67887 2.3999 4.81491l1.206-1.59668c-1.00692-.74359-1.60239-1.91837-1.606-3.16841-.00152-2.20456 1.78811-3.99293 3.99725-3.99445s4.00123 1.7844 4.00275 3.98895c.00067.97319-.35495 1.91311-1 2.643v-1.63959h-2V18h5v-1.99585h-1.549c.99003-1.09558 1.54141-2.51645 1.549-3.9917z" />
</svg>
    </HvIconBase>
)};

Running.propTypes = HvIconBase.propTypes;

export default Running;