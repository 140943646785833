
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleStandingLowerClothesHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleStandingLowerClothesHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M19.5 10.87193a5.98271 5.98271 0 002.494-4.861H24v-1.9991h-2.342a5.99579 5.99579 0 00-11.315 0H8V6.011h2a5.97971 5.97971 0 002.5 4.861C9.268 12.54022 7 16.44957 7 21.00465c0 .33586.013.66971.037.99957H12V32h8v-9.99578h4.963c.024-.32986.037-.66371.037-.99957 0-4.55508-2.268-8.46443-5.5-10.13272zM16 2.01267a3.98232 3.98232 0 013.445 1.99916h-6.89A3.98232 3.98232 0 0116 2.01267zM12 6.011h8a4 4 0 01-8 0zM9.043 20.00507a10.03334 10.03334 0 012.141-5.51067A6.25282 6.25282 0 0116 12.00845a6.25084 6.25084 0 014.816 2.48795 10.0273 10.0273 0 012.141 5.50867z" />
</svg>
    </HvIconBase>
)};

MaleStandingLowerClothesHat.propTypes = HvIconBase.propTypes;

export default MaleStandingLowerClothesHat;