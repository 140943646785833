
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Deploy = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Deploy" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M.141 15.152l4.24264-4.24264.70711.70711-4.24264 4.24264zm1.06011-3.18189l2.12132-2.12132-.70711-.70711L.494 11.263zm3.536 3.536l2.12132-2.12132-.70711-.70711L4.03 14.799zM16 7l-1.731 1.749 1.075 3.222-2.829 2.829-2.128-2.13-1.407 1.422L1.909 7.02l1.42-1.407L1.2 3.485 4.03.656l3.222 1.075L9 0h7zM2.616 3.486L4.03 4.9l2.391-2.393L4.3 1.8zM14.2 11.7l-.709-2.121L11.1 11.97l1.415 1.413zM15 1H9.412L3.326 7.024l5.65 5.65L15 6.588z" />
</svg>
    </HvIconBase>
)};

Deploy.propTypes = HvIconBase.propTypes;

export default Deploy;