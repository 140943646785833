
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleStandingHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleStandingHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M19.5 10.86719a5.99083 5.99083 0 002.5-4.863h2v-1.9998h-2.342a6.00021 6.00021 0 00-11.316 0H8v1.99976h2a5.98965 5.98965 0 002.5 4.86206C9.269 12.535 7 16.44434 7 21.00171c0 .33789.011.67078.036.99975H9v5.99915h3V32h8v-3.99939h3v-5.99915h1.963c.024-.33.037-.66284.037-.99975 0-4.55737-2.269-8.46571-5.5-10.13452zM16 2.00464a3.99993 3.99993 0 013.463 1.99975h-6.926A3.99993 3.99993 0 0116 2.00464zm-4 3.99951h8a4 4 0 01-8 0zM21 20.002v5.999h-3v3.99926h-4V26.001h-3v-5.999H9.044a10.052 10.052 0 012.141-5.51123A6.25263 6.25263 0 0116 12.00317a6.2483 6.2483 0 014.816 2.48755 10.04357 10.04357 0 012.142 5.51128z" />
</svg>
    </HvIconBase>
)};

FemaleStandingHat.propTypes = HvIconBase.propTypes;

export default FemaleStandingHat;