
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const DaemonSet = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="DaemonSet" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" fill={colorArray[0]} className="color0"  viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[1]} className="color1"  d="M0 15V1h16v14H0zm1-1h14V2H1v12zm1-3.083V6.57593l3.495-1.521.19201.08301 3.31397 1.43701V10.918l-3.50501 1.6369L2 10.917zm.97705-.635l2.03394.9541V8.73608l-2.03394-.96899V10.282zm3.01001-1.53298v2.48388l2.03491-.9499V7.84204l-2.03491.90698zM3.58704 6.96606l1.92199.91382 1.97901-.88281-1.99109-.86304-1.90991.83203zm6.90986 3.58884L9.271 9.98096V8.88794l.743.34814v-.60815l.5.19995.48-.19995v.6001l.518-.24292v1.09179L10.5 10.55l-.0031.0049zm2.0141-.93991V8.52295l.517-.24097v-.5061l.976-.40991v1.552l-1.49.69995-.003-.00293zm-2.007-1.86694l-.49-.19995v-.81006h-.0059v-.03711l-.73613-.33789v-.22803l.87303-.427.365.17407.391-.17505.61.26587v.54517l-.518.23095v.79493l-.487.19995-.002.00415zm2.523-1.05713v-.84888l-.517.229v-.75292l-.374-.16309.353-.15698-.475-.20606 1.169-.57202.8201.35498v1.70703l-.9751.40796-.001.00098zM8.271 5.9021l-1.26795-.58203V4.5769l.65393-.28491 1.23804.54004-.30798.13403.40002.1919-.71912.35205v.39307L8.271 5.9021zm1.876-1.61499l-1.23794-.54004 1.58894-.69214.1919.08399 1.2872.5581-1.1691.57202-.31-.13403-.349.1521h-.002z" />
</svg>
    </HvIconBase>
)};

DaemonSet.propTypes = HvIconBase.propTypes;

export default DaemonSet;