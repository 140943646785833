
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const ToolAlt = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    ["none",theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="ToolAlt" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  stroke="#414141" strokeMiterlimit={10} strokeWidth={3} d="M-6.5-5.5h29v29h-29z" />
  <path fill={colorArray[1]} className="color1"  d="M14.07336 0L10.9421 1.40949l-.7493 2.24909-4.09861 4.09961-1.0759-1.0759L2.86761 8.83l.71688.71681L0 13.1325 2.86761 16l3.58449-3.58543.71693.71682 2.15068-2.1497-1.07484-1.07491 4.0986-4.09751 2.248-.74937L16 1.92664zM2.86761 14.56514l-1.4338-1.43264 2.86761-2.86874 1.43381 1.43388zm4.30139-2.8675L4.30142 8.83l.71687-.71694 2.86762 2.86764zm6.68023-7.46422l-2.05333.68451-4.25881 4.25879-.71693-.71694L11.079 4.201l.68444-2.05426 2.08687-.94.93894.943z" />
</svg>
    </HvIconBase>
)};

ToolAlt.propTypes = HvIconBase.propTypes;

export default ToolAlt;