
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleStandingHat = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleStandingHat" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M19.49992 10.864A5.9887 5.9887 0 0022.0003 6H24V4h-2.342a6 6 0 00-11.316 0H8v2h2.0003a5.98772 5.98772 0 002.50039 4.862C9.26819 12.531 6.99985 16.442 6.99985 21c0 .336.013.67.037 1H12v10h8V22h4.96376c.024-.33.037-.664.037-1 0-4.556-2.26834-8.467-5.50084-10.136zM16 2.0003A4.00012 4.00012 0 0119.463 4h-6.926A4.00012 4.00012 0 0116 2.0003zM12 6h8a4 4 0 01-8 0zm6 14v10h-4V20H9.043a10.04 10.04 0 012.141-5.513A6.253 6.253 0 0116 12a6.25 6.25 0 014.816 2.489A10.034 10.034 0 0122.957 20z" />
</svg>
    </HvIconBase>
)};

MaleStandingHat.propTypes = HvIconBase.propTypes;

export default MaleStandingHat;