
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Severity3 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Severity3" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M7.59668 8.79346c.11426.23779.1709.51758.1709.83984 0 .63477-.22949 1.13281-.68848 1.49414s-1.0918.54199-1.89941.54199-1.46777-.12549-1.98242-.37598v-1.10352c.3252.15283.67188.27344 1.03711.36133.36719.08789.70801.13184 1.02344.13184.46191 0 .80273-.08789 1.02344-.26367.21875-.17578.3291-.41162.3291-.70801 0-.26709-.10059-.49316-.30273-.67871s-.61816-.40527-1.25-.65918c-.65138-.26367-1.11036-.56494-1.37697-.90332s-.40039-.74561-.40039-1.2207c0-.5957.21191-1.06445.63477-1.40625s.99121-.5127 1.7041-.5127c.68359 0 1.36426.1499 2.04102.44922l-.3711.95215c-.63476-.26709-1.20117-.40039-1.69922-.40039-.37793 0-.66406.08203-.85938.24658s-.29296.38184-.29296.65186c0 .18555.03906.34424.11719.47607s.20703.25635.38574.37354.50098.27197.9668.46387c.52441.21826.9082.42139 1.15234.61035s.42285.40185.53711.63965zm3.61133-.94239v-.03906c.47852-.104.84961-.30664 1.11328-.60791s.39551-.67773.39551-1.13037c0-.54346-.20215-.97021-.60547-1.2793s-.96484-.46387-1.68457-.46387c-.86621 0-1.62598.24072-2.28027.72266l.56152.80078c.3418-.22119.6377-.37012.88867-.44678s.51074-.11475.78125-.11475c.3877 0 .68066.08301.87891.24902s.29785.40186.29785.70801c0 .7876-.56934 1.18164-1.70898 1.18164h-.62989v.93262h.62012c.64746 0 1.12305.08936 1.42578.26855s.4541.45898.4541.83984c0 .42969-.13672.74707-.41016.95215s-.68652.30762-1.24023.30762c-.3125 0-.6377-.04053-.97656-.12207s-.65918-.19678-.96191-.34668v1.02051c.54395.25732 1.21094.38574 2.00195.38574.89551 0 1.58496-.18652 2.07031-.55908s.72754-.90234.72754-1.58936c0-.47217-.14355-.85059-.42969-1.13525s-.71582-.46288-1.28906-.53466zM16 8c0 4.41827-3.58179 8-8 8s-8-3.58173-8-8 3.58179-8 8-8 8 3.58173 8 8zm-1 0c0-3.86603-3.13403-7-7-7S1 4.13397 1 8c0 3.86597 3.13403 7 7 7s7-3.13403 7-7z" />
</svg>
    </HvIconBase>
)};

Severity3.propTypes = HvIconBase.propTypes;

export default Severity3;