
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 20, height: 20 };
    default:
    case "S":
      return { width: 24, height: 24 };
    case "M":
      return { width: 40, height: 40 };
    case "L":
      return { width: 104, height: 104 };
  }
};

const Level1 = ({
  color,
  iconSize = "S",
  viewbox = "0 0 24 24",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1,theme.palette.atmo2];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Level1" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <circle cx={12} cy={12} r={12} fill={colorArray[0]} className="color0"  />
  <path fill={colorArray[1]} className="color1"  d="M8.864311555 14.121317631l5.6568542495-5.6568542494L15.935379367 9.878676944l-5.6568542495 5.6568542495z" />
</svg>
    </HvIconBase>
)};

Level1.propTypes = HvIconBase.propTypes;

export default Level1;