
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Speedometer = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Speedometer" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M8 0a7.99582 7.99582 0 00-3.61359 15.13062l.406-.91333a6.98868 6.98868 0 01-3.757-5.71875L3 8.5l.00018-.99854L1.02527 7.5a6.96066 6.96066 0 011.68884-4.07983l1.40607 1.408.7063-.70606L3.42023 2.714a6.962 6.962 0 014.08044-1.68885l-.00134 1.97461 1 .00024.00134-1.97473a6.96132 6.96132 0 014.079 1.6886l-1.40773 1.40625.70593.7063 1.4079-1.4065a6.962 6.962 0 011.68908 4.08154L13.00037 7.5l-.00019.99854L14.9646 8.5a6.98878 6.98878 0 01-3.757 5.71729l.406.91333A7.99582 7.99582 0 008 0zM1.00024 8.00391L1 8l.00031-.00537zM7.99817 1H8l.00183.00012zm1.8305 4.46448l.70685.7074-2.8294 2.82739-.70685-.7074z" />
</svg>
    </HvIconBase>
)};

Speedometer.propTypes = HvIconBase.propTypes;

export default Speedometer;