
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const ArchiveTeam = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="ArchiveTeam" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M16 3H8L6 1H0v14h.888A5.42736 5.42736 0 00.6 16h10.8a5.427 5.427 0 00-.292-1H16zM1.995 15A4.458 4.458 0 0110 15zM6 11a2 2 0 112-2 2.00006 2.00006 0 01-2 2zm4.584 3a5.54966 5.54966 0 00-2.6-2.12207A3.985 3.985 0 0114.949 14zM8.995 8.98291a2.49239 2.49239 0 00.765.676 4.96276 4.96276 0 00-.951.354A2.96468 2.96468 0 009 9zM9.5 7.5A1.5 1.5 0 1111 9a1.5 1.5 0 01-1.5-1.5zm5.5 4.031a5.02581 5.02581 0 00-2.764-1.8711 2.493 2.493 0 10-3.723-2.28783A2.994 2.994 0 104.661 11.672 5.522 5.522 0 001.416 14H1V2h4.586l1.707 1.707.293.293H15z" />
</svg>
    </HvIconBase>
)};

ArchiveTeam.propTypes = HvIconBase.propTypes;

export default ArchiveTeam;