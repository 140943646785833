
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const HeavyShowerNight = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="HeavyShowerNight" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M6.303 15.805l.982.195 1.199-4.826-.969-.244-1.212 4.875z" />
  <path fill={colorArray[0]} className="color0"  d="M13.616 9.312A5.011 5.011 0 0016 5.493a3.985 3.985 0 01-5.454-1.507 4.053 4.053 0 010-3.986 5.022 5.022 0 00-4.52 5.012H6a4 4 0 00-3.9 3.16 3.023 3.023 0 00.9 5.9h1.518l-.21.846.969.243.99-3.987-.967-.244-.529 2.135H3a2.015 2.015 0 01-.6-3.933l.551-.176.124-.568A3.022 3.022 0 016 6.018c.044 0 .085.014.128.016A3.055 3.055 0 018.5 7.369l.5.757.8-.426a1.488 1.488 0 012.018.627 1.514 1.514 0 01.168.576c0 .045.014.088.014.133v.024l-.025.847.089.018.736.15a1.511 1.511 0 01-.3 2.99h-2.482l.469-1.891-.97-.244-.99 3.988.97.243.271-1.089H12.5a2.514 2.514 0 001.115-4.76zm-.671-.785a2.5 2.5 0 00-2.443-2 2.475 2.475 0 00-1.167.29 4 4 0 00-2.3-1.662c0-.047-.014-.091-.014-.138a4.033 4.033 0 012.022-3.5c-.015.159-.023.32-.023.481a5.022 5.022 0 005 5.034c.161 0 .321-.008.478-.023a3.826 3.826 0 01-1.553 1.518z" />
</svg>
    </HvIconBase>
)};

HeavyShowerNight.propTypes = HvIconBase.propTypes;

export default HeavyShowerNight;