
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const MaleStandingUpperClothes = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="MaleStandingUpperClothes" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M25 21.01009a13.40948 13.40948 0 00-.093-1.49863c-.013-.11789-.027-.23378-.044-.35067a12.685 12.685 0 00-.7-2.75947c-.028-.07184-.0566-.14368-.08605-.21812a11.045 11.045 0 00-1.24423-2.30392c-.09189-.13022-.18157-.25487-.27572-.3773a9.70729 9.70729 0 00-1.782-1.79835c-.079-.06094-.158-.11989-.238-.17683a8.32831 8.32831 0 00-1.041-.65041 6.00258 6.00258 0 10-7 0 8.41027 8.41027 0 00-1.039.64841c-.19736.14187-.38314.28953-.58522.45693a8.82667 8.82667 0 00-1.355 1.41007c-.09188.11925-.1818.23618-.26978.359a11.25243 11.25243 0 00-.711 1.099 11.92148 11.92148 0 00-.576 1.22288c-.044.1069-.087.21481-.128.3237a12.496 12.496 0 00-.425 1.3168l-.006.025a12.85975 12.85975 0 00-.264 1.39872c-.018.12488-.033.24877-.047.37466A13.60541 13.60541 0 007 20.9921v.018c0 .33569.013.66938.037.99908H12V32h8v-9.99083h4.964c.024-.3287.036-.66239.036-.99908zM12.15887 6.02384a4 4 0 114 3.99634 3.99817 3.99817 0 01-4-3.99634zM18 30.00183h-4v-7.99266h4z" />
</svg>
    </HvIconBase>
)};

MaleStandingUpperClothes.propTypes = HvIconBase.propTypes;

export default MaleStandingUpperClothes;