
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "../IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const FemaleStanding = ({
  color,
  iconSize = "S",
  viewbox = "0 0 32 32",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="FemaleStanding" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M19.5 10.87016a6.001 6.001 0 10-7 0C9.268 12.5386 7 16.44727 7 21.00373c0 .33688.011.66977.036.99966H9v5.998h3V32h8v-3.99864h3v-5.998h1.963c.024-.32889.037-.66178.037-.99966 0-4.55643-2.268-8.4651-5.5-10.13354zM12 6.00881a4 4 0 114 3.99865 4.00432 4.00432 0 01-4-3.99865zm9 13.99526v5.998h-3v3.99865h-4V26.002h-3v-5.998H9.043a10.03659 10.03659 0 012.141-5.50913A6.25264 6.25264 0 0116 12.00678a6.24864 6.24864 0 014.814 2.48715 10.03066 10.03066 0 012.144 5.51014z" />
</svg>
    </HvIconBase>
)};

FemaleStanding.propTypes = HvIconBase.propTypes;

export default FemaleStanding;