
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const Hold = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="Hold" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M14 3.55851a1.52162 1.52162 0 00-.92791-1.44931 1.63244 1.63244 0 00-1.12809-.00109 1.48323 1.48323 0 00-.052-.15894 1.50006 1.50006 0 00-1.944-.842 1.5 1.5 0 00-2.894 0A1.5 1.5 0 005 2.50813v6.719L3.381 8.3022a.52689.52689 0 00-.061-.03906l-.133-.072-.044-.021c-.051-.0249-.114-.05493-.14-.06494a1.5 1.5 0 00-1.938.86 1.47707 1.47707 0 00.791 1.9l2.289 1.3269A5 5 0 0014 11.00813zm-1 7.44962a4 4 0 01-7.929.74012.49264.49264 0 00-.235-.311l-.709-.40808-1.773-1.021-.068-.03894a.5.5 0 01.18-.968.46429.46429 0 01.168.03s.06806.03492.1.04895l2.526 1.428a.5.5 0 00.74-.441V2.6086a.514.514 0 01.684-.465 1.14859 1.14859 0 01.186.12793 1.49991 1.49991 0 01.13.28v5.557h1v-6.567l.018-.06494a.5.5 0 01.966 0l.016.061V8.1086h1v-5.84l.315-.12207a.494.494 0 01.383 0 .52424.52424 0 01.284.32813l.018.064V8.1086h1V3.27254l.316-.127a.51715.51715 0 01.184-.037.5.5 0 01.5.5z" />
</svg>
    </HvIconBase>
)};

Hold.propTypes = HvIconBase.propTypes;

export default Hold;