
import React from "react";
import { useTheme } from "@material-ui/core";
import HvIconBase from "./IconBase";

const sizeSelector = (iconSize, height, width) => {
  if (height && width) {
    return { width, height };
  }

  switch (iconSize) {
    case "XS":
      return { width: 12, height: 12 };
    default:
    case "S":
      return { width: 16, height: 16 };
    case "M":
      return { width: 32, height: 32 };
    case "L":
      return { width: 96, height: 96 };
  }
};

const AppleLogoFilled = ({
  color,
  iconSize = "S",
  viewbox = "0 0 16 16",
  height,
  width,
  semantic,
  inverted = false,
  svgProps,
  ...others
}) => {
  const theme = useTheme();
  const getColor = c => theme.palette?.[c] || c;
  const colorArray = 
    (typeof color === "string" && [getColor(color)]) ||
    (Array.isArray(color) && color.map?.(getColor)) ||
    [theme.palette.acce1];

  if (semantic) {
    colorArray[0] = theme.palette?.[semantic] || colorArray[0];
  }

  if (inverted && colorArray[1]) {
    colorArray[1] = colorArray[0];
    colorArray[0] = "none";
  }

  const size = sizeSelector(iconSize, height, width);

  return (
    <HvIconBase name="AppleLogoFilled" iconSize={iconSize ?? "S"} {...others}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewbox} height={size.height} width={size.width}   focusable={false} {...svgProps}>
  <path fill={colorArray[0]} className="color0"  d="M10.53389 2.768a3.853 3.853 0 01-2.92 1.216 3.383 3.383 0 011.041-2.667 4.572 4.572 0 012.9-1.317 3.589 3.589 0 01-1.021 2.768zm.522 1.251c-1.317 0-2.258.718-2.873.718s-1.557-.71-2.635-.71a3.917 3.917 0 00-3.291 2.031c-1.4 2.409-.361 5.977.991 7.942.662.97 1.46 2 2.538 2 .988-.036 1.321-.6 2.514-.6s1.558.6 2.636.6 1.733-.989 2.393-1.961a8.5542 8.5542 0 001.08-2.23 3.52 3.52 0 01-2.1-3.205 3.587 3.587 0 011.7-3.006 3.681 3.681 0 00-2.831-1.575z" />
</svg>
    </HvIconBase>
)};

AppleLogoFilled.propTypes = HvIconBase.propTypes;

export default AppleLogoFilled;